import i18n from './i18n'

export default [
  {
    emoji: '😀',
    description: `${i18n.t('emojis.list.grinning-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'grinning',
    ],
    tags: [
      'smile',
      'happy',
    ],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    emoji: '😃',
    description: `${i18n.t('emojis.list.grinning-face-with-big-eyes')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'smiley',
    ],
    tags: [
      'happy',
      'joy',
      'haha',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😄',
    description: `${i18n.t('emojis.list.grinning-face-with-smiling-eyes')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'smile',
    ],
    tags: [
      'happy',
      'joy',
      'laugh',
      'pleased',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😁',
    description: `${i18n.t('emojis.list.beaming-face-with-smiling-eyes')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'grin',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😆',
    description: `${i18n.t('emojis.list.grinning-squinting-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'laughing',
      'satisfied',
    ],
    tags: [
      'happy',
      'haha',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😅',
    description: `${i18n.t('emojis.list.grinning-face-with-sweat')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'sweat_smile',
    ],
    tags: [
      'hot',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🤣',
    description: `${i18n.t('emojis.list.rolling-on-the-floor-laughing')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'rofl',
    ],
    tags: [
      'lol',
      'laughing',
    ],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '😂',
    description: `${i18n.t('emojis.list.face-with-tears-of-joy')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'joy',
    ],
    tags: [
      'tears',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🙂',
    description: `${i18n.t('emojis.list.slightly-smiling-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'slightly_smiling_face',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🙃',
    description: `${i18n.t('emojis.list.upside-down-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'upside_down_face',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🫠',
    description: `${i18n.t('emojis.list.melting-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'melting_face',
    ],
    tags: [
      'sarcasm',
      'dread',
    ],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '😉',
    description: `${i18n.t('emojis.list.winking-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'wink',
    ],
    tags: [
      'flirt',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😊',
    description: `${i18n.t('emojis.list.smiling-face-with-smiling-eyes')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'blush',
    ],
    tags: [
      'proud',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😇',
    description: `${i18n.t('emojis.list.smiling-face-with-halo')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'innocent',
    ],
    tags: [
      'angel',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥰',
    description: `${i18n.t('emojis.list.smiling-face-with-hearts')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'smiling_face_with_three_hearts',
    ],
    tags: [
      'love',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '😍',
    description: `${i18n.t('emojis.list.smiling-face-with-heart-eyes')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'heart_eyes',
    ],
    tags: [
      'love',
      'crush',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🤩',
    description: `${i18n.t('emojis.list.star-struck')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'star_struck',
    ],
    tags: [
      'eyes',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '😘',
    description: `${i18n.t('emojis.list.face-blowing-a-kiss')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'kissing_heart',
    ],
    tags: [
      'flirt',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😗',
    description: `${i18n.t('emojis.list.kissing-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'kissing',
    ],
    tags: [],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    emoji: '☺️',
    description: `${i18n.t('emojis.list.smiling-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'relaxed',
    ],
    tags: [
      'blush',
      'pleased',
    ],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '😚',
    description: `${i18n.t('emojis.list.kissing-face-with-closed-eyes')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'kissing_closed_eyes',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😙',
    description: `${i18n.t('emojis.list.kissing-face-with-smiling-eyes')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'kissing_smiling_eyes',
    ],
    tags: [],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    emoji: '🥲',
    description: `${i18n.t('emojis.list.smiling-face-with-tear')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'smiling_face_with_tear',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '😋',
    description: `${i18n.t('emojis.list.face-savoring-food')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'yum',
    ],
    tags: [
      'tongue',
      'lick',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😛',
    description: `${i18n.t('emojis.list.face-with-tongue')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'stuck_out_tongue',
    ],
    tags: [],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    emoji: '😜',
    description: `${i18n.t('emojis.list.winking-face-with-tongue')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'stuck_out_tongue_winking_eye',
    ],
    tags: [
      'prank',
      'silly',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🤪',
    description: `${i18n.t('emojis.list.zany-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'zany_face',
    ],
    tags: [
      'goofy',
      'wacky',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '😝',
    description: `${i18n.t('emojis.list.squinting-face-with-tongue')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'stuck_out_tongue_closed_eyes',
    ],
    tags: [
      'prank',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🤑',
    description: `${i18n.t('emojis.list.money-mouth-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'money_mouth_face',
    ],
    tags: [
      'rich',
    ],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🤗',
    description: `${i18n.t('emojis.list.smiling-face-with-open-hands')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'hugs',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🤭',
    description: `${i18n.t('emojis.list.face-with-hand-over-mouth')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'hand_over_mouth',
    ],
    tags: [
      'quiet',
      'whoops',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🫢',
    description: `${i18n.t('emojis.list.face-with-open-eyes-and-hand-over-mouth')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'face_with_open_eyes_and_hand_over_mouth',
    ],
    tags: [
      'gasp',
      'shock',
    ],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🫣',
    description: `${i18n.t('emojis.list.face-with-peeking-eye')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'face_with_peeking_eye',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🤫',
    description: `${i18n.t('emojis.list.shushing-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'shushing_face',
    ],
    tags: [
      'silence',
      'quiet',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🤔',
    description: `${i18n.t('emojis.list.thinking-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'thinking',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🫡',
    description: `${i18n.t('emojis.list.saluting-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'saluting_face',
    ],
    tags: [
      'respect',
    ],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🤐',
    description: `${i18n.t('emojis.list.zipper-mouth-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'zipper_mouth_face',
    ],
    tags: [
      'silence',
      'hush',
    ],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🤨',
    description: `${i18n.t('emojis.list.face-with-raised-eyebrow')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'raised_eyebrow',
    ],
    tags: [
      'suspicious',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '😐',
    description: `${i18n.t('emojis.list.neutral-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'neutral_face',
    ],
    tags: [
      'meh',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😑',
    description: `${i18n.t('emojis.list.expressionless-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'expressionless',
    ],
    tags: [],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    emoji: '😶',
    description: `${i18n.t('emojis.list.face-without-mouth')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'no_mouth',
    ],
    tags: [
      'mute',
      'silence',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🫥',
    description: `${i18n.t('emojis.list.dotted-line-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'dotted_line_face',
    ],
    tags: [
      'invisible',
    ],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '😶‍🌫️',
    description: `${i18n.t('emojis.list.face-in-clouds')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'face_in_clouds',
    ],
    tags: [],
    unicode_version: '13.1',
    ios_version: '14.0',
  },
  {
    emoji: '😏',
    description: `${i18n.t('emojis.list.smirking-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'smirk',
    ],
    tags: [
      'smug',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😒',
    description: `${i18n.t('emojis.list.unamused-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'unamused',
    ],
    tags: [
      'meh',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🙄',
    description: `${i18n.t('emojis.list.face-with-rolling-eyes')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'roll_eyes',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '😬',
    description: `${i18n.t('emojis.list.grimacing-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'grimacing',
    ],
    tags: [],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    emoji: '😮‍💨',
    description: `${i18n.t('emojis.list.face-exhaling')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'face_exhaling',
    ],
    tags: [],
    unicode_version: '13.1',
    ios_version: '14.0',
  },
  {
    emoji: '🤥',
    description: `${i18n.t('emojis.list.lying-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'lying_face',
    ],
    tags: [
      'liar',
    ],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '😌',
    description: `${i18n.t('emojis.list.relieved-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'relieved',
    ],
    tags: [
      'whew',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😔',
    description: `${i18n.t('emojis.list.pensive-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'pensive',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😪',
    description: `${i18n.t('emojis.list.sleepy-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'sleepy',
    ],
    tags: [
      'tired',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🤤',
    description: `${i18n.t('emojis.list.drooling-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'drooling_face',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '😴',
    description: `${i18n.t('emojis.list.sleeping-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'sleeping',
    ],
    tags: [
      'zzz',
    ],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    emoji: '😷',
    description: `${i18n.t('emojis.list.face-with-medical-mask')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'mask',
    ],
    tags: [
      'sick',
      'ill',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🤒',
    description: `${i18n.t('emojis.list.face-with-thermometer')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'face_with_thermometer',
    ],
    tags: [
      'sick',
    ],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🤕',
    description: `${i18n.t('emojis.list.face-with-head-bandage')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'face_with_head_bandage',
    ],
    tags: [
      'hurt',
    ],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🤢',
    description: `${i18n.t('emojis.list.nauseated-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'nauseated_face',
    ],
    tags: [
      'sick',
      'barf',
      'disgusted',
    ],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🤮',
    description: `${i18n.t('emojis.list.face-vomiting')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'vomiting_face',
    ],
    tags: [
      'barf',
      'sick',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🤧',
    description: `${i18n.t('emojis.list.sneezing-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'sneezing_face',
    ],
    tags: [
      'achoo',
      'sick',
    ],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🥵',
    description: `${i18n.t('emojis.list.hot-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'hot_face',
    ],
    tags: [
      'heat',
      'sweating',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🥶',
    description: `${i18n.t('emojis.list.cold-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'cold_face',
    ],
    tags: [
      'freezing',
      'ice',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🥴',
    description: `${i18n.t('emojis.list.woozy-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'woozy_face',
    ],
    tags: [
      'groggy',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '😵',
    description: `${i18n.t('emojis.list.face-with-crossed-out-eyes')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'dizzy_face',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😵‍💫',
    description: `${i18n.t('emojis.list.face-with-spiral-eyes')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'face_with_spiral_eyes',
    ],
    tags: [],
    unicode_version: '13.1',
    ios_version: '14.0',
  },
  {
    emoji: '🤯',
    description: `${i18n.t('emojis.list.exploding-head')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'exploding_head',
    ],
    tags: [
      'mind',
      'blown',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🤠',
    description: `${i18n.t('emojis.list.cowboy-hat-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'cowboy_hat_face',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🥳',
    description: `${i18n.t('emojis.list.partying-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'partying_face',
    ],
    tags: [
      'celebration',
      'birthday',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🥸',
    description: `${i18n.t('emojis.list.disguised-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'disguised_face',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '😎',
    description: `${i18n.t('emojis.list.smiling-face-with-sunglasses')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'sunglasses',
    ],
    tags: [
      'cool',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🤓',
    description: `${i18n.t('emojis.list.nerd-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'nerd_face',
    ],
    tags: [
      'geek',
      'glasses',
    ],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🧐',
    description: `${i18n.t('emojis.list.face-with-monocle')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'monocle_face',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '😕',
    description: `${i18n.t('emojis.list.confused-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'confused',
    ],
    tags: [],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    emoji: '🫤',
    description: `${i18n.t('emojis.list.face-with-diagonal-mouth')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'face_with_diagonal_mouth',
    ],
    tags: [
      'confused',
    ],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '😟',
    description: `${i18n.t('emojis.list.worried-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'worried',
    ],
    tags: [
      'nervous',
    ],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    emoji: '🙁',
    description: `${i18n.t('emojis.list.slightly-frowning-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'slightly_frowning_face',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '☹️',
    description: `${i18n.t('emojis.list.frowning-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'frowning_face',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    emoji: '😮',
    description: `${i18n.t('emojis.list.face-with-open-mouth')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'open_mouth',
    ],
    tags: [
      'surprise',
      'impressed',
      'wow',
    ],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    emoji: '😯',
    description: `${i18n.t('emojis.list.hushed-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'hushed',
    ],
    tags: [
      'silence',
      'speechless',
    ],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    emoji: '😲',
    description: `${i18n.t('emojis.list.astonished-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'astonished',
    ],
    tags: [
      'amazed',
      'gasp',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😳',
    description: `${i18n.t('emojis.list.flushed-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'flushed',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥺',
    description: `${i18n.t('emojis.list.pleading-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'pleading_face',
    ],
    tags: [
      'puppy',
      'eyes',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🥹',
    description: `${i18n.t('emojis.list.face-holding-back-tears')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'face_holding_back_tears',
    ],
    tags: [
      'tears',
      'gratitude',
    ],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '😦',
    description: `${i18n.t('emojis.list.frowning-face-with-open-mouth')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'frowning',
    ],
    tags: [],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    emoji: '😧',
    description: `${i18n.t('emojis.list.anguished-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'anguished',
    ],
    tags: [
      'stunned',
    ],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    emoji: '😨',
    description: `${i18n.t('emojis.list.fearful-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'fearful',
    ],
    tags: [
      'scared',
      'shocked',
      'oops',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😰',
    description: `${i18n.t('emojis.list.anxious-face-with-sweat')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'cold_sweat',
    ],
    tags: [
      'nervous',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😥',
    description: `${i18n.t('emojis.list.sad-but-relieved-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'disappointed_relieved',
    ],
    tags: [
      'phew',
      'sweat',
      'nervous',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😢',
    description: `${i18n.t('emojis.list.crying-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'cry',
    ],
    tags: [
      'sad',
      'tear',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😭',
    description: `${i18n.t('emojis.list.loudly-crying-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'sob',
    ],
    tags: [
      'sad',
      'cry',
      'bawling',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😱',
    description: `${i18n.t('emojis.list.face-screaming-in-fear')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'scream',
    ],
    tags: [
      'horror',
      'shocked',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😖',
    description: `${i18n.t('emojis.list.confounded-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'confounded',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😣',
    description: `${i18n.t('emojis.list.persevering-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'persevere',
    ],
    tags: [
      'struggling',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😞',
    description: `${i18n.t('emojis.list.disappointed-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'disappointed',
    ],
    tags: [
      'sad',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😓',
    description: `${i18n.t('emojis.list.downcast-face-with-sweat')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'sweat',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😩',
    description: `${i18n.t('emojis.list.weary-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'weary',
    ],
    tags: [
      'tired',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😫',
    description: `${i18n.t('emojis.list.tired-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'tired_face',
    ],
    tags: [
      'upset',
      'whine',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥱',
    description: `${i18n.t('emojis.list.yawning-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'yawning_face',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '😤',
    description: `${i18n.t('emojis.list.face-with-steam-from-nose')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'triumph',
    ],
    tags: [
      'smug',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😡',
    description: `${i18n.t('emojis.list.pouting-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'rage',
      'pout',
    ],
    tags: [
      'angry',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😠',
    description: `${i18n.t('emojis.list.angry-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'angry',
    ],
    tags: [
      'mad',
      'annoyed',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🤬',
    description: `${i18n.t('emojis.list.face-with-symbols-on-mouth')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'cursing_face',
    ],
    tags: [
      'foul',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '😈',
    description: `${i18n.t('emojis.list.smiling-face-with-horns')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'smiling_imp',
    ],
    tags: [
      'devil',
      'evil',
      'horns',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👿',
    description: `${i18n.t('emojis.list.angry-face-with-horns')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'imp',
    ],
    tags: [
      'angry',
      'devil',
      'evil',
      'horns',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💀',
    description: `${i18n.t('emojis.list.skull')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'skull',
    ],
    tags: [
      'dead',
      'danger',
      'poison',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '☠️',
    description: `${i18n.t('emojis.list.skull-and-crossbones')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'skull_and_crossbones',
    ],
    tags: [
      'danger',
      'pirate',
    ],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    emoji: '💩',
    description: `${i18n.t('emojis.list.pile-of-poo')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'hankey',
      'poop',
      'shit',
    ],
    tags: [
      'crap',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🤡',
    description: `${i18n.t('emojis.list.clown-face')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'clown_face',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '👹',
    description: `${i18n.t('emojis.list.ogre')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'japanese_ogre',
    ],
    tags: [
      'monster',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👺',
    description: `${i18n.t('emojis.list.goblin')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'japanese_goblin',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👻',
    description: `${i18n.t('emojis.list.ghost')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'ghost',
    ],
    tags: [
      'halloween',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👽',
    description: `${i18n.t('emojis.list.alien')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'alien',
    ],
    tags: [
      'ufo',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👾',
    description: `${i18n.t('emojis.list.alien-monster')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'space_invader',
    ],
    tags: [
      'game',
      'retro',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🤖',
    description: `${i18n.t('emojis.list.robot')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'robot',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '😺',
    description: `${i18n.t('emojis.list.grinning-cat')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'smiley_cat',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😸',
    description: `${i18n.t('emojis.list.grinning-cat-with-smiling-eyes')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'smile_cat',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😹',
    description: `${i18n.t('emojis.list.cat-with-tears-of-joy')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'joy_cat',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😻',
    description: `${i18n.t('emojis.list.smiling-cat-with-heart-eyes')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'heart_eyes_cat',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😼',
    description: `${i18n.t('emojis.list.cat-with-wry-smile')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'smirk_cat',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😽',
    description: `${i18n.t('emojis.list.kissing-cat')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'kissing_cat',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🙀',
    description: `${i18n.t('emojis.list.weary-cat')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'scream_cat',
    ],
    tags: [
      'horror',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😿',
    description: `${i18n.t('emojis.list.crying-cat')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'crying_cat_face',
    ],
    tags: [
      'sad',
      'tear',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '😾',
    description: `${i18n.t('emojis.list.pouting-cat')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'pouting_cat',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🙈',
    description: `${i18n.t('emojis.list.see-no-evil-monkey')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'see_no_evil',
    ],
    tags: [
      'monkey',
      'blind',
      'ignore',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🙉',
    description: `${i18n.t('emojis.list.hear-no-evil-monkey')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'hear_no_evil',
    ],
    tags: [
      'monkey',
      'deaf',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🙊',
    description: `${i18n.t('emojis.list.speak-no-evil-monkey')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'speak_no_evil',
    ],
    tags: [
      'monkey',
      'mute',
      'hush',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💋',
    description: `${i18n.t('emojis.list.kiss-mark')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'kiss',
    ],
    tags: [
      'lipstick',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💌',
    description: `${i18n.t('emojis.list.love-letter')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'love_letter',
    ],
    tags: [
      'email',
      'envelope',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💘',
    description: `${i18n.t('emojis.list.heart-with-arrow')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'cupid',
    ],
    tags: [
      'love',
      'heart',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💝',
    description: `${i18n.t('emojis.list.heart-with-ribbon')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'gift_heart',
    ],
    tags: [
      'chocolates',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💖',
    description: `${i18n.t('emojis.list.sparkling-heart')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'sparkling_heart',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💗',
    description: `${i18n.t('emojis.list.growing-heart')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'heartpulse',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💓',
    description: `${i18n.t('emojis.list.beating-heart')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'heartbeat',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💞',
    description: `${i18n.t('emojis.list.revolving-hearts')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'revolving_hearts',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💕',
    description: `${i18n.t('emojis.list.two-hearts')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'two_hearts',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💟',
    description: `${i18n.t('emojis.list.heart-decoration')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'heart_decoration',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '❣️',
    description: `${i18n.t('emojis.list.heart-exclamation')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'heavy_heart_exclamation',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    emoji: '💔',
    description: `${i18n.t('emojis.list.broken-heart')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'broken_heart',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '❤️‍🔥',
    description: `${i18n.t('emojis.list.heart-on-fire')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'heart_on_fire',
    ],
    tags: [],
    unicode_version: '13.1',
    ios_version: '14.0',
  },
  {
    emoji: '❤️‍🩹',
    description: `${i18n.t('emojis.list.mending-heart')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'mending_heart',
    ],
    tags: [],
    unicode_version: '13.1',
    ios_version: '14.0',
  },
  {
    emoji: '❤️',
    description: `${i18n.t('emojis.list.red-heart')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'heart',
    ],
    tags: [
      'love',
    ],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '🧡',
    description: `${i18n.t('emojis.list.orange-heart')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'orange_heart',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '💛',
    description: `${i18n.t('emojis.list.yellow-heart')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'yellow_heart',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💚',
    description: `${i18n.t('emojis.list.green-heart')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'green_heart',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💙',
    description: `${i18n.t('emojis.list.blue-heart')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'blue_heart',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💜',
    description: `${i18n.t('emojis.list.purple-heart')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'purple_heart',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🤎',
    description: `${i18n.t('emojis.list.brown-heart')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'brown_heart',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🖤',
    description: `${i18n.t('emojis.list.black-heart')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'black_heart',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🤍',
    description: `${i18n.t('emojis.list.white-heart')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'white_heart',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '💯',
    description: `${i18n.t('emojis.list.hundred-points')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      '100',
    ],
    tags: [
      'score',
      'perfect',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💢',
    description: `${i18n.t('emojis.list.anger-symbol')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'anger',
    ],
    tags: [
      'angry',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💥',
    description: `${i18n.t('emojis.list.collision')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'boom',
      'collision',
    ],
    tags: [
      'explode',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💫',
    description: `${i18n.t('emojis.list.dizzy')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'dizzy',
    ],
    tags: [
      'star',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💦',
    description: `${i18n.t('emojis.list.sweat-droplets')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'sweat_drops',
    ],
    tags: [
      'water',
      'workout',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💨',
    description: `${i18n.t('emojis.list.dashing-away')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'dash',
    ],
    tags: [
      'wind',
      'blow',
      'fast',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕳️',
    description: `${i18n.t('emojis.list.hole')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'hole',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '💣',
    description: `${i18n.t('emojis.list.bomb')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'bomb',
    ],
    tags: [
      'boom',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💬',
    description: `${i18n.t('emojis.list.speech-balloon')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'speech_balloon',
    ],
    tags: [
      'comment',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👁️‍🗨️',
    description: `${i18n.t('emojis.list.eye-in-speech-bubble')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'eye_speech_bubble',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🗨️',
    description: `${i18n.t('emojis.list.left-speech-bubble')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'left_speech_bubble',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🗯️',
    description: `${i18n.t('emojis.list.right-anger-bubble')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'right_anger_bubble',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '💭',
    description: `${i18n.t('emojis.list.thought-balloon')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'thought_balloon',
    ],
    tags: [
      'thinking',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💤',
    description: `${i18n.t('emojis.list.zzz')}`,
    category: `${i18n.t('emojis.categories.smileys-emotion')}`,
    aliases: [
      'zzz',
    ],
    tags: [
      'sleeping',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👋',
    description: `${i18n.t('emojis.list.waving-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'wave',
    ],
    tags: [
      'goodbye',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🤚',
    description: `${i18n.t('emojis.list.raised-back-of-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'raised_back_of_hand',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🖐️',
    description: `${i18n.t('emojis.list.hand-with-fingers-splayed')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'raised_hand_with_fingers_splayed',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    emoji: '✋',
    description: `${i18n.t('emojis.list.raised-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'hand',
      'raised_hand',
    ],
    tags: [
      'highfive',
      'stop',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🖖',
    description: `${i18n.t('emojis.list.vulcan-salute')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'vulcan_salute',
    ],
    tags: [
      'prosper',
      'spock',
    ],
    unicode_version: '7.0',
    ios_version: '8.3',
    skin_tones: true,
  },
  {
    emoji: '🫱',
    description: `${i18n.t('emojis.list.rightwards-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'rightwards_hand',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    emoji: '🫲',
    description: `${i18n.t('emojis.list.leftwards-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'leftwards_hand',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    emoji: '🫳',
    description: `${i18n.t('emojis.list.palm-down-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'palm_down_hand',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    emoji: '🫴',
    description: `${i18n.t('emojis.list.palm-up-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'palm_up_hand',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    emoji: '👌',
    description: `${i18n.t('emojis.list.ok-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'ok_hand',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🤌',
    description: `${i18n.t('emojis.list.pinched-fingers')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'pinched_fingers',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    emoji: '🤏',
    description: `${i18n.t('emojis.list.pinching-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'pinching_hand',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    emoji: '✌️',
    description: `${i18n.t('emojis.list.victory-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'v',
    ],
    tags: [
      'victory',
      'peace',
    ],
    unicode_version: '',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🤞',
    description: `${i18n.t('emojis.list.crossed-fingers')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'crossed_fingers',
    ],
    tags: [
      'luck',
      'hopeful',
    ],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🫰',
    description: `${i18n.t('emojis.list.hand-with-index-finger-and-thumb-crossed')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'hand_with_index_finger_and_thumb_crossed',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    emoji: '🤟',
    description: `${i18n.t('emojis.list.love-you-gesture')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'love_you_gesture',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🤘',
    description: `${i18n.t('emojis.list.sign-of-the-horns')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'metal',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    emoji: '🤙',
    description: `${i18n.t('emojis.list.call-me-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'call_me_hand',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👈',
    description: `${i18n.t('emojis.list.backhand-index-pointing-left')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'point_left',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '👉',
    description: `${i18n.t('emojis.list.backhand-index-pointing-right')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'point_right',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '👆',
    description: `${i18n.t('emojis.list.backhand-index-pointing-up')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'point_up_2',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🖕',
    description: `${i18n.t('emojis.list.middle-finger')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'middle_finger',
      'fu',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    emoji: '👇',
    description: `${i18n.t('emojis.list.backhand-index-pointing-down')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'point_down',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '☝️',
    description: `${i18n.t('emojis.list.index-pointing-up')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'point_up',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🫵',
    description: `${i18n.t('emojis.list.index-pointing-at-the-viewer')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'index_pointing_at_the_viewer',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    emoji: '👍',
    description: `${i18n.t('emojis.list.thumbs-up')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      '+1',
      'thumbsup',
    ],
    tags: [
      'approve',
      'ok',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '👎',
    description: `${i18n.t('emojis.list.thumbs-down')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      '-1',
      'thumbsdown',
    ],
    tags: [
      'disapprove',
      'bury',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '✊',
    description: `${i18n.t('emojis.list.raised-fist')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'fist_raised',
      'fist',
    ],
    tags: [
      'power',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '👊',
    description: `${i18n.t('emojis.list.oncoming-fist')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'fist_oncoming',
      'facepunch',
      'punch',
    ],
    tags: [
      'attack',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🤛',
    description: `${i18n.t('emojis.list.left-facing-fist')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'fist_left',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🤜',
    description: `${i18n.t('emojis.list.right-facing-fist')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'fist_right',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👏',
    description: `${i18n.t('emojis.list.clapping-hands')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'clap',
    ],
    tags: [
      'praise',
      'applause',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🙌',
    description: `${i18n.t('emojis.list.raising-hands')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'raised_hands',
    ],
    tags: [
      'hooray',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🫶',
    description: `${i18n.t('emojis.list.heart-hands')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'heart_hands',
    ],
    tags: [
      'love',
    ],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    emoji: '👐',
    description: `${i18n.t('emojis.list.open-hands')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'open_hands',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🤲',
    description: `${i18n.t('emojis.list.palms-up-together')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'palms_up_together',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🤝',
    description: `${i18n.t('emojis.list.handshake')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'handshake',
    ],
    tags: [
      'deal',
    ],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🙏',
    description: `${i18n.t('emojis.list.folded-hands')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'pray',
    ],
    tags: [
      'please',
      'hope',
      'wish',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '✍️',
    description: `${i18n.t('emojis.list.writing-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'writing_hand',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    emoji: '💅',
    description: `${i18n.t('emojis.list.nail-polish')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'nail_care',
    ],
    tags: [
      'beauty',
      'manicure',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🤳',
    description: `${i18n.t('emojis.list.selfie')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'selfie',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '💪',
    description: `${i18n.t('emojis.list.flexed-biceps')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'muscle',
    ],
    tags: [
      'flex',
      'bicep',
      'strong',
      'workout',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🦾',
    description: `${i18n.t('emojis.list.mechanical-arm')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'mechanical_arm',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🦿',
    description: `${i18n.t('emojis.list.mechanical-leg')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'mechanical_leg',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🦵',
    description: `${i18n.t('emojis.list.leg')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'leg',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🦶',
    description: `${i18n.t('emojis.list.foot')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'foot',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '👂',
    description: `${i18n.t('emojis.list.ear')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'ear',
    ],
    tags: [
      'hear',
      'sound',
      'listen',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🦻',
    description: `${i18n.t('emojis.list.ear-with-hearing-aid')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'ear_with_hearing_aid',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    emoji: '👃',
    description: `${i18n.t('emojis.list.nose')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'nose',
    ],
    tags: [
      'smell',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🧠',
    description: `${i18n.t('emojis.list.brain')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'brain',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🫀',
    description: `${i18n.t('emojis.list.anatomical-heart')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'anatomical_heart',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🫁',
    description: `${i18n.t('emojis.list.lungs')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'lungs',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🦷',
    description: `${i18n.t('emojis.list.tooth')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'tooth',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🦴',
    description: `${i18n.t('emojis.list.bone')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'bone',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '👀',
    description: `${i18n.t('emojis.list.eyes')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'eyes',
    ],
    tags: [
      'look',
      'see',
      'watch',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👁️',
    description: `${i18n.t('emojis.list.eye')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'eye',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '👅',
    description: `${i18n.t('emojis.list.tongue')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'tongue',
    ],
    tags: [
      'taste',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👄',
    description: `${i18n.t('emojis.list.mouth')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'lips',
    ],
    tags: [
      'kiss',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🫦',
    description: `${i18n.t('emojis.list.biting-lip')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'biting_lip',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '👶',
    description: `${i18n.t('emojis.list.baby')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'baby',
    ],
    tags: [
      'child',
      'newborn',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🧒',
    description: `${i18n.t('emojis.list.child')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'child',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '👦',
    description: `${i18n.t('emojis.list.boy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'boy',
    ],
    tags: [
      'child',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '👧',
    description: `${i18n.t('emojis.list.girl')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'girl',
    ],
    tags: [
      'child',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🧑',
    description: `${i18n.t('emojis.list.person')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'adult',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '👱',
    description: `${i18n.t('emojis.list.person-blond-hair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'blond_haired_person',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '👨',
    description: `${i18n.t('emojis.list.man')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man',
    ],
    tags: [
      'mustache',
      'father',
      'dad',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🧔',
    description: `${i18n.t('emojis.list.person-beard')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'bearded_person',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧔‍♂️',
    description: `${i18n.t('emojis.list.man-beard')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_beard',
    ],
    tags: [],
    unicode_version: '13.1',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    emoji: '🧔‍♀️',
    description: `${i18n.t('emojis.list.woman-beard')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_beard',
    ],
    tags: [],
    unicode_version: '13.1',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    emoji: '👨‍🦰',
    description: `${i18n.t('emojis.list.man-red-hair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'red_haired_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '👨‍🦱',
    description: `${i18n.t('emojis.list.man-curly-hair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'curly_haired_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '👨‍🦳',
    description: `${i18n.t('emojis.list.man-white-hair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'white_haired_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '👨‍🦲',
    description: `${i18n.t('emojis.list.man-bald')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'bald_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '👩',
    description: `${i18n.t('emojis.list.woman')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman',
    ],
    tags: [
      'girls',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '👩‍🦰',
    description: `${i18n.t('emojis.list.woman-red-hair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'red_haired_woman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🦰',
    description: `${i18n.t('emojis.list.person-red-hair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'person_red_hair',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍🦱',
    description: `${i18n.t('emojis.list.woman-curly-hair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'curly_haired_woman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🦱',
    description: `${i18n.t('emojis.list.person-curly-hair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'person_curly_hair',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍🦳',
    description: `${i18n.t('emojis.list.woman-white-hair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'white_haired_woman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🦳',
    description: `${i18n.t('emojis.list.person-white-hair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'person_white_hair',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍🦲',
    description: `${i18n.t('emojis.list.woman-bald')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'bald_woman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🦲',
    description: `${i18n.t('emojis.list.person-bald')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'person_bald',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👱‍♀️',
    description: `${i18n.t('emojis.list.woman-blond-hair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'blond_haired_woman',
      'blonde_woman',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '👱‍♂️',
    description: `${i18n.t('emojis.list.man-blond-hair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'blond_haired_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧓',
    description: `${i18n.t('emojis.list.older-person')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'older_adult',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '👴',
    description: `${i18n.t('emojis.list.old-man')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'older_man',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '👵',
    description: `${i18n.t('emojis.list.old-woman')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'older_woman',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🙍',
    description: `${i18n.t('emojis.list.person-frowning')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'frowning_person',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🙍‍♂️',
    description: `${i18n.t('emojis.list.man-frowning')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'frowning_man',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '🙍‍♀️',
    description: `${i18n.t('emojis.list.woman-frowning')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'frowning_woman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🙎',
    description: `${i18n.t('emojis.list.person-pouting')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'pouting_face',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🙎‍♂️',
    description: `${i18n.t('emojis.list.man-pouting')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'pouting_man',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '🙎‍♀️',
    description: `${i18n.t('emojis.list.woman-pouting')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'pouting_woman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🙅',
    description: `${i18n.t('emojis.list.person-gesturing-no')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'no_good',
    ],
    tags: [
      'stop',
      'halt',
      'denied',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🙅‍♂️',
    description: `${i18n.t('emojis.list.man-gesturing-no')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'no_good_man',
      'ng_man',
    ],
    tags: [
      'stop',
      'halt',
      'denied',
    ],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '🙅‍♀️',
    description: `${i18n.t('emojis.list.woman-gesturing-no')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'no_good_woman',
      'ng_woman',
    ],
    tags: [
      'stop',
      'halt',
      'denied',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🙆',
    description: `${i18n.t('emojis.list.person-gesturing-ok')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'ok_person',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🙆‍♂️',
    description: `${i18n.t('emojis.list.man-gesturing-ok')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'ok_man',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '🙆‍♀️',
    description: `${i18n.t('emojis.list.woman-gesturing-ok')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'ok_woman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '💁',
    description: `${i18n.t('emojis.list.person-tipping-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'tipping_hand_person',
      'information_desk_person',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '💁‍♂️',
    description: `${i18n.t('emojis.list.man-tipping-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'tipping_hand_man',
      'sassy_man',
    ],
    tags: [
      'information',
    ],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '💁‍♀️',
    description: `${i18n.t('emojis.list.woman-tipping-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'tipping_hand_woman',
      'sassy_woman',
    ],
    tags: [
      'information',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🙋',
    description: `${i18n.t('emojis.list.person-raising-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'raising_hand',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🙋‍♂️',
    description: `${i18n.t('emojis.list.man-raising-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'raising_hand_man',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '🙋‍♀️',
    description: `${i18n.t('emojis.list.woman-raising-hand')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'raising_hand_woman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧏',
    description: `${i18n.t('emojis.list.deaf-person')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'deaf_person',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    emoji: '🧏‍♂️',
    description: `${i18n.t('emojis.list.deaf-man')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'deaf_man',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    emoji: '🧏‍♀️',
    description: `${i18n.t('emojis.list.deaf-woman')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'deaf_woman',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    emoji: '🙇',
    description: `${i18n.t('emojis.list.person-bowing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'bow',
    ],
    tags: [
      'respect',
      'thanks',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🙇‍♂️',
    description: `${i18n.t('emojis.list.man-bowing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'bowing_man',
    ],
    tags: [
      'respect',
      'thanks',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🙇‍♀️',
    description: `${i18n.t('emojis.list.woman-bowing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'bowing_woman',
    ],
    tags: [
      'respect',
      'thanks',
    ],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '🤦',
    description: `${i18n.t('emojis.list.person-facepalming')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'facepalm',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🤦‍♂️',
    description: `${i18n.t('emojis.list.man-facepalming')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_facepalming',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🤦‍♀️',
    description: `${i18n.t('emojis.list.woman-facepalming')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_facepalming',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🤷',
    description: `${i18n.t('emojis.list.person-shrugging')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'shrug',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🤷‍♂️',
    description: `${i18n.t('emojis.list.man-shrugging')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_shrugging',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🤷‍♀️',
    description: `${i18n.t('emojis.list.woman-shrugging')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_shrugging',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧑‍⚕️',
    description: `${i18n.t('emojis.list.health-worker')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'health_worker',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍⚕️',
    description: `${i18n.t('emojis.list.man-health-worker')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_health_worker',
    ],
    tags: [
      'doctor',
      'nurse',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍⚕️',
    description: `${i18n.t('emojis.list.woman-health-worker')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_health_worker',
    ],
    tags: [
      'doctor',
      'nurse',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🎓',
    description: `${i18n.t('emojis.list.student')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'student',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍🎓',
    description: `${i18n.t('emojis.list.man-student')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_student',
    ],
    tags: [
      'graduation',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍🎓',
    description: `${i18n.t('emojis.list.woman-student')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_student',
    ],
    tags: [
      'graduation',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🏫',
    description: `${i18n.t('emojis.list.teacher')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'teacher',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍🏫',
    description: `${i18n.t('emojis.list.man-teacher')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_teacher',
    ],
    tags: [
      'school',
      'professor',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍🏫',
    description: `${i18n.t('emojis.list.woman-teacher')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_teacher',
    ],
    tags: [
      'school',
      'professor',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧑‍⚖️',
    description: `${i18n.t('emojis.list.judge')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'judge',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍⚖️',
    description: `${i18n.t('emojis.list.man-judge')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_judge',
    ],
    tags: [
      'justice',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍⚖️',
    description: `${i18n.t('emojis.list.woman-judge')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_judge',
    ],
    tags: [
      'justice',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🌾',
    description: `${i18n.t('emojis.list.farmer')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'farmer',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍🌾',
    description: `${i18n.t('emojis.list.man-farmer')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_farmer',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍🌾',
    description: `${i18n.t('emojis.list.woman-farmer')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_farmer',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🍳',
    description: `${i18n.t('emojis.list.cook')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'cook',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍🍳',
    description: `${i18n.t('emojis.list.man-cook')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_cook',
    ],
    tags: [
      'chef',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍🍳',
    description: `${i18n.t('emojis.list.woman-cook')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_cook',
    ],
    tags: [
      'chef',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🔧',
    description: `${i18n.t('emojis.list.mechanic')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'mechanic',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍🔧',
    description: `${i18n.t('emojis.list.man-mechanic')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_mechanic',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍🔧',
    description: `${i18n.t('emojis.list.woman-mechanic')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_mechanic',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🏭',
    description: `${i18n.t('emojis.list.factory-worker')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'factory_worker',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍🏭',
    description: `${i18n.t('emojis.list.man-factory-worker')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_factory_worker',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍🏭',
    description: `${i18n.t('emojis.list.woman-factory-worker')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_factory_worker',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧑‍💼',
    description: `${i18n.t('emojis.list.office-worker')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'office_worker',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍💼',
    description: `${i18n.t('emojis.list.man-office-worker')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_office_worker',
    ],
    tags: [
      'business',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍💼',
    description: `${i18n.t('emojis.list.woman-office-worker')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_office_worker',
    ],
    tags: [
      'business',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🔬',
    description: `${i18n.t('emojis.list.scientist')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'scientist',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍🔬',
    description: `${i18n.t('emojis.list.man-scientist')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_scientist',
    ],
    tags: [
      'research',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍🔬',
    description: `${i18n.t('emojis.list.woman-scientist')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_scientist',
    ],
    tags: [
      'research',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧑‍💻',
    description: `${i18n.t('emojis.list.technologist')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'technologist',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍💻',
    description: `${i18n.t('emojis.list.man-technologist')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_technologist',
    ],
    tags: [
      'coder',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍💻',
    description: `${i18n.t('emojis.list.woman-technologist')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_technologist',
    ],
    tags: [
      'coder',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🎤',
    description: `${i18n.t('emojis.list.singer')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'singer',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍🎤',
    description: `${i18n.t('emojis.list.man-singer')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_singer',
    ],
    tags: [
      'rockstar',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍🎤',
    description: `${i18n.t('emojis.list.woman-singer')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_singer',
    ],
    tags: [
      'rockstar',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🎨',
    description: `${i18n.t('emojis.list.artist')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'artist',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍🎨',
    description: `${i18n.t('emojis.list.man-artist')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_artist',
    ],
    tags: [
      'painter',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍🎨',
    description: `${i18n.t('emojis.list.woman-artist')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_artist',
    ],
    tags: [
      'painter',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧑‍✈️',
    description: `${i18n.t('emojis.list.pilot')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'pilot',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍✈️',
    description: `${i18n.t('emojis.list.man-pilot')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_pilot',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍✈️',
    description: `${i18n.t('emojis.list.woman-pilot')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_pilot',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🚀',
    description: `${i18n.t('emojis.list.astronaut')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'astronaut',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍🚀',
    description: `${i18n.t('emojis.list.man-astronaut')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_astronaut',
    ],
    tags: [
      'space',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍🚀',
    description: `${i18n.t('emojis.list.woman-astronaut')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_astronaut',
    ],
    tags: [
      'space',
    ],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🚒',
    description: `${i18n.t('emojis.list.firefighter')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'firefighter',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍🚒',
    description: `${i18n.t('emojis.list.man-firefighter')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_firefighter',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👩‍🚒',
    description: `${i18n.t('emojis.list.woman-firefighter')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_firefighter',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👮',
    description: `${i18n.t('emojis.list.police-officer')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'police_officer',
      'cop',
    ],
    tags: [
      'law',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '👮‍♂️',
    description: `${i18n.t('emojis.list.man-police-officer')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'policeman',
    ],
    tags: [
      'law',
      'cop',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '👮‍♀️',
    description: `${i18n.t('emojis.list.woman-police-officer')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'policewoman',
    ],
    tags: [
      'law',
      'cop',
    ],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '🕵️',
    description: `${i18n.t('emojis.list.detective')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'detective',
    ],
    tags: [
      'sleuth',
    ],
    unicode_version: '7.0',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    emoji: '🕵️‍♂️',
    description: `${i18n.t('emojis.list.man-detective')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'male_detective',
    ],
    tags: [
      'sleuth',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🕵️‍♀️',
    description: `${i18n.t('emojis.list.woman-detective')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'female_detective',
    ],
    tags: [
      'sleuth',
    ],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '💂',
    description: `${i18n.t('emojis.list.guard')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'guard',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '💂‍♂️',
    description: `${i18n.t('emojis.list.man-guard')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'guardsman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '💂‍♀️',
    description: `${i18n.t('emojis.list.woman-guard')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'guardswoman',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '🥷',
    description: `${i18n.t('emojis.list.ninja')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'ninja',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    emoji: '👷',
    description: `${i18n.t('emojis.list.construction-worker')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'construction_worker',
    ],
    tags: [
      'helmet',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '👷‍♂️',
    description: `${i18n.t('emojis.list.man-construction-worker')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'construction_worker_man',
    ],
    tags: [
      'helmet',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '👷‍♀️',
    description: `${i18n.t('emojis.list.woman-construction-worker')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'construction_worker_woman',
    ],
    tags: [
      'helmet',
    ],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '🫅',
    description: `${i18n.t('emojis.list.person-with-crown')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'person_with_crown',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    emoji: '🤴',
    description: `${i18n.t('emojis.list.prince')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'prince',
    ],
    tags: [
      'crown',
      'royal',
    ],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '👸',
    description: `${i18n.t('emojis.list.princess')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'princess',
    ],
    tags: [
      'crown',
      'royal',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '👳',
    description: `${i18n.t('emojis.list.person-wearing-turban')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'person_with_turban',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '👳‍♂️',
    description: `${i18n.t('emojis.list.man-wearing-turban')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_with_turban',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '👳‍♀️',
    description: `${i18n.t('emojis.list.woman-wearing-turban')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_with_turban',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '👲',
    description: `${i18n.t('emojis.list.person-with-skullcap')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_with_gua_pi_mao',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🧕',
    description: `${i18n.t('emojis.list.woman-with-headscarf')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_with_headscarf',
    ],
    tags: [
      'hijab',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🤵',
    description: `${i18n.t('emojis.list.person-in-tuxedo')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'person_in_tuxedo',
    ],
    tags: [
      'groom',
      'marriage',
      'wedding',
    ],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🤵‍♂️',
    description: `${i18n.t('emojis.list.man-in-tuxedo')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_in_tuxedo',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    emoji: '🤵‍♀️',
    description: `${i18n.t('emojis.list.woman-in-tuxedo')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_in_tuxedo',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    emoji: '👰',
    description: `${i18n.t('emojis.list.person-with-veil')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'person_with_veil',
    ],
    tags: [
      'marriage',
      'wedding',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '👰‍♂️',
    description: `${i18n.t('emojis.list.man-with-veil')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_with_veil',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    emoji: '👰‍♀️',
    description: `${i18n.t('emojis.list.woman-with-veil')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_with_veil',
      'bride_with_veil',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    emoji: '🤰',
    description: `${i18n.t('emojis.list.pregnant-woman')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'pregnant_woman',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🫃',
    description: `${i18n.t('emojis.list.pregnant-man')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'pregnant_man',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    emoji: '🫄',
    description: `${i18n.t('emojis.list.pregnant-person')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'pregnant_person',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    emoji: '🤱',
    description: `${i18n.t('emojis.list.breast-feeding')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'breast_feeding',
    ],
    tags: [
      'nursing',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '👩‍🍼',
    description: `${i18n.t('emojis.list.woman-feeding-baby')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_feeding_baby',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    emoji: '👨‍🍼',
    description: `${i18n.t('emojis.list.man-feeding-baby')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_feeding_baby',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🍼',
    description: `${i18n.t('emojis.list.person-feeding-baby')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'person_feeding_baby',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    emoji: '👼',
    description: `${i18n.t('emojis.list.baby-angel')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'angel',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🎅',
    description: `${i18n.t('emojis.list.santa-claus')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'santa',
    ],
    tags: [
      'christmas',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🤶',
    description: `${i18n.t('emojis.list.mrs-claus')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'mrs_claus',
    ],
    tags: [
      'santa',
    ],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🎄',
    description: `${i18n.t('emojis.list.mx-claus')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'mx_claus',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    emoji: '🦸',
    description: `${i18n.t('emojis.list.superhero')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'superhero',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🦸‍♂️',
    description: `${i18n.t('emojis.list.man-superhero')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'superhero_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🦸‍♀️',
    description: `${i18n.t('emojis.list.woman-superhero')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'superhero_woman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🦹',
    description: `${i18n.t('emojis.list.supervillain')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'supervillain',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🦹‍♂️',
    description: `${i18n.t('emojis.list.man-supervillain')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'supervillain_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🦹‍♀️',
    description: `${i18n.t('emojis.list.woman-supervillain')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'supervillain_woman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧙',
    description: `${i18n.t('emojis.list.mage')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'mage',
    ],
    tags: [
      'wizard',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧙‍♂️',
    description: `${i18n.t('emojis.list.man-mage')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'mage_man',
    ],
    tags: [
      'wizard',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧙‍♀️',
    description: `${i18n.t('emojis.list.woman-mage')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'mage_woman',
    ],
    tags: [
      'wizard',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧚',
    description: `${i18n.t('emojis.list.fairy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'fairy',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧚‍♂️',
    description: `${i18n.t('emojis.list.man-fairy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'fairy_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧚‍♀️',
    description: `${i18n.t('emojis.list.woman-fairy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'fairy_woman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧛',
    description: `${i18n.t('emojis.list.vampire')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'vampire',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧛‍♂️',
    description: `${i18n.t('emojis.list.man-vampire')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'vampire_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧛‍♀️',
    description: `${i18n.t('emojis.list.woman-vampire')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'vampire_woman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧜',
    description: `${i18n.t('emojis.list.merperson')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'merperson',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧜‍♂️',
    description: `${i18n.t('emojis.list.merman')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'merman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧜‍♀️',
    description: `${i18n.t('emojis.list.mermaid')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'mermaid',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧝',
    description: `${i18n.t('emojis.list.elf')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'elf',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧝‍♂️',
    description: `${i18n.t('emojis.list.man-elf')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'elf_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧝‍♀️',
    description: `${i18n.t('emojis.list.woman-elf')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'elf_woman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧞',
    description: `${i18n.t('emojis.list.genie')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'genie',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧞‍♂️',
    description: `${i18n.t('emojis.list.man-genie')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'genie_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧞‍♀️',
    description: `${i18n.t('emojis.list.woman-genie')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'genie_woman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧟',
    description: `${i18n.t('emojis.list.zombie')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'zombie',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧟‍♂️',
    description: `${i18n.t('emojis.list.man-zombie')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'zombie_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧟‍♀️',
    description: `${i18n.t('emojis.list.woman-zombie')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'zombie_woman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧌',
    description: `${i18n.t('emojis.list.troll')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'troll',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '💆',
    description: `${i18n.t('emojis.list.person-getting-massage')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'massage',
    ],
    tags: [
      'spa',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '💆‍♂️',
    description: `${i18n.t('emojis.list.man-getting-massage')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'massage_man',
    ],
    tags: [
      'spa',
    ],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '💆‍♀️',
    description: `${i18n.t('emojis.list.woman-getting-massage')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'massage_woman',
    ],
    tags: [
      'spa',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '💇',
    description: `${i18n.t('emojis.list.person-getting-haircut')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'haircut',
    ],
    tags: [
      'beauty',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '💇‍♂️',
    description: `${i18n.t('emojis.list.man-getting-haircut')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'haircut_man',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '💇‍♀️',
    description: `${i18n.t('emojis.list.woman-getting-haircut')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'haircut_woman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🚶',
    description: `${i18n.t('emojis.list.person-walking')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'walking',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🚶‍♂️',
    description: `${i18n.t('emojis.list.man-walking')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'walking_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🚶‍♀️',
    description: `${i18n.t('emojis.list.woman-walking')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'walking_woman',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '🧍',
    description: `${i18n.t('emojis.list.person-standing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'standing_person',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    emoji: '🧍‍♂️',
    description: `${i18n.t('emojis.list.man-standing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'standing_man',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    emoji: '🧍‍♀️',
    description: `${i18n.t('emojis.list.woman-standing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'standing_woman',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    emoji: '🧎',
    description: `${i18n.t('emojis.list.person-kneeling')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'kneeling_person',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    emoji: '🧎‍♂️',
    description: `${i18n.t('emojis.list.man-kneeling')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'kneeling_man',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    emoji: '🧎‍♀️',
    description: `${i18n.t('emojis.list.woman-kneeling')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'kneeling_woman',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🦯',
    description: `${i18n.t('emojis.list.person-with-white-cane')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'person_with_probing_cane',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍🦯',
    description: `${i18n.t('emojis.list.man-with-white-cane')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_with_probing_cane',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    emoji: '👩‍🦯',
    description: `${i18n.t('emojis.list.woman-with-white-cane')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_with_probing_cane',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🦼',
    description: `${i18n.t('emojis.list.person-in-motorized-wheelchair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'person_in_motorized_wheelchair',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍🦼',
    description: `${i18n.t('emojis.list.man-in-motorized-wheelchair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_in_motorized_wheelchair',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    emoji: '👩‍🦼',
    description: `${i18n.t('emojis.list.woman-in-motorized-wheelchair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_in_motorized_wheelchair',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🦽',
    description: `${i18n.t('emojis.list.person-in-manual-wheelchair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'person_in_manual_wheelchair',
    ],
    tags: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    emoji: '👨‍🦽',
    description: `${i18n.t('emojis.list.man-in-manual-wheelchair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_in_manual_wheelchair',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    emoji: '👩‍🦽',
    description: `${i18n.t('emojis.list.woman-in-manual-wheelchair')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_in_manual_wheelchair',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    emoji: '🏃',
    description: `${i18n.t('emojis.list.person-running')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'runner',
      'running',
    ],
    tags: [
      'exercise',
      'workout',
      'marathon',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🏃‍♂️',
    description: `${i18n.t('emojis.list.man-running')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'running_man',
    ],
    tags: [
      'exercise',
      'workout',
      'marathon',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🏃‍♀️',
    description: `${i18n.t('emojis.list.woman-running')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'running_woman',
    ],
    tags: [
      'exercise',
      'workout',
      'marathon',
    ],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '💃',
    description: `${i18n.t('emojis.list.woman-dancing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_dancing',
      'dancer',
    ],
    tags: [
      'dress',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🕺',
    description: `${i18n.t('emojis.list.man-dancing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_dancing',
    ],
    tags: [
      'dancer',
    ],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🕴️',
    description: `${i18n.t('emojis.list.person-in-suit-levitating')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'business_suit_levitating',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    emoji: '👯',
    description: `${i18n.t('emojis.list.people-with-bunny-ears')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'dancers',
    ],
    tags: [
      'bunny',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👯‍♂️',
    description: `${i18n.t('emojis.list.men-with-bunny-ears')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'dancing_men',
    ],
    tags: [
      'bunny',
    ],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    emoji: '👯‍♀️',
    description: `${i18n.t('emojis.list.women-with-bunny-ears')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'dancing_women',
    ],
    tags: [
      'bunny',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧖',
    description: `${i18n.t('emojis.list.person-in-steamy-room')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'sauna_person',
    ],
    tags: [
      'steamy',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧖‍♂️',
    description: `${i18n.t('emojis.list.man-in-steamy-room')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'sauna_man',
    ],
    tags: [
      'steamy',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧖‍♀️',
    description: `${i18n.t('emojis.list.woman-in-steamy-room')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'sauna_woman',
    ],
    tags: [
      'steamy',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧗',
    description: `${i18n.t('emojis.list.person-climbing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'climbing',
    ],
    tags: [
      'bouldering',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧗‍♂️',
    description: `${i18n.t('emojis.list.man-climbing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'climbing_man',
    ],
    tags: [
      'bouldering',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧗‍♀️',
    description: `${i18n.t('emojis.list.woman-climbing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'climbing_woman',
    ],
    tags: [
      'bouldering',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🤺',
    description: `${i18n.t('emojis.list.person-fencing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'person_fencing',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🏇',
    description: `${i18n.t('emojis.list.horse-racing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'horse_racing',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '⛷️',
    description: `${i18n.t('emojis.list.skier')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'skier',
    ],
    tags: [],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    emoji: '🏂',
    description: `${i18n.t('emojis.list.snowboarder')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'snowboarder',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🏌️',
    description: `${i18n.t('emojis.list.person-golfing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'golfing',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    emoji: '🏌️‍♂️',
    description: `${i18n.t('emojis.list.man-golfing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'golfing_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🏌️‍♀️',
    description: `${i18n.t('emojis.list.woman-golfing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'golfing_woman',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '🏄',
    description: `${i18n.t('emojis.list.person-surfing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'surfer',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🏄‍♂️',
    description: `${i18n.t('emojis.list.man-surfing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'surfing_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🏄‍♀️',
    description: `${i18n.t('emojis.list.woman-surfing')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'surfing_woman',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '🚣',
    description: `${i18n.t('emojis.list.person-rowing-boat')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'rowboat',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🚣‍♂️',
    description: `${i18n.t('emojis.list.man-rowing-boat')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'rowing_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🚣‍♀️',
    description: `${i18n.t('emojis.list.woman-rowing-boat')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'rowing_woman',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '🏊',
    description: `${i18n.t('emojis.list.person-swimming')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'swimmer',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🏊‍♂️',
    description: `${i18n.t('emojis.list.man-swimming')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'swimming_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🏊‍♀️',
    description: `${i18n.t('emojis.list.woman-swimming')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'swimming_woman',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '⛹️',
    description: `${i18n.t('emojis.list.person-bouncing-ball')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'bouncing_ball_person',
    ],
    tags: [
      'basketball',
    ],
    unicode_version: '5.2',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    emoji: '⛹️‍♂️',
    description: `${i18n.t('emojis.list.man-bouncing-ball')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'bouncing_ball_man',
      'basketball_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '⛹️‍♀️',
    description: `${i18n.t('emojis.list.woman-bouncing-ball')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'bouncing_ball_woman',
      'basketball_woman',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '🏋️',
    description: `${i18n.t('emojis.list.person-lifting-weights')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'weight_lifting',
    ],
    tags: [
      'gym',
      'workout',
    ],
    unicode_version: '7.0',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    emoji: '🏋️‍♂️',
    description: `${i18n.t('emojis.list.man-lifting-weights')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'weight_lifting_man',
    ],
    tags: [
      'gym',
      'workout',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🏋️‍♀️',
    description: `${i18n.t('emojis.list.woman-lifting-weights')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'weight_lifting_woman',
    ],
    tags: [
      'gym',
      'workout',
    ],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '🚴',
    description: `${i18n.t('emojis.list.person-biking')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'bicyclist',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🚴‍♂️',
    description: `${i18n.t('emojis.list.man-biking')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'biking_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🚴‍♀️',
    description: `${i18n.t('emojis.list.woman-biking')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'biking_woman',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '🚵',
    description: `${i18n.t('emojis.list.person-mountain-biking')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'mountain_bicyclist',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🚵‍♂️',
    description: `${i18n.t('emojis.list.man-mountain-biking')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'mountain_biking_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🚵‍♀️',
    description: `${i18n.t('emojis.list.woman-mountain-biking')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'mountain_biking_woman',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    emoji: '🤸',
    description: `${i18n.t('emojis.list.person-cartwheeling')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'cartwheeling',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🤸‍♂️',
    description: `${i18n.t('emojis.list.man-cartwheeling')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_cartwheeling',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🤸‍♀️',
    description: `${i18n.t('emojis.list.woman-cartwheeling')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_cartwheeling',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🤼',
    description: `${i18n.t('emojis.list.people-wrestling')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'wrestling',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🤼‍♂️',
    description: `${i18n.t('emojis.list.men-wrestling')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'men_wrestling',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🤼‍♀️',
    description: `${i18n.t('emojis.list.women-wrestling')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'women_wrestling',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🤽',
    description: `${i18n.t('emojis.list.person-playing-water-polo')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'water_polo',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🤽‍♂️',
    description: `${i18n.t('emojis.list.man-playing-water-polo')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_playing_water_polo',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🤽‍♀️',
    description: `${i18n.t('emojis.list.woman-playing-water-polo')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_playing_water_polo',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🤾',
    description: `${i18n.t('emojis.list.person-playing-handball')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'handball_person',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🤾‍♂️',
    description: `${i18n.t('emojis.list.man-playing-handball')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_playing_handball',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🤾‍♀️',
    description: `${i18n.t('emojis.list.woman-playing-handball')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_playing_handball',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🤹',
    description: `${i18n.t('emojis.list.person-juggling')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'juggling_person',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🤹‍♂️',
    description: `${i18n.t('emojis.list.man-juggling')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'man_juggling',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🤹‍♀️',
    description: `${i18n.t('emojis.list.woman-juggling')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'woman_juggling',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    emoji: '🧘',
    description: `${i18n.t('emojis.list.person-in-lotus-position')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'lotus_position',
    ],
    tags: [
      'meditation',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧘‍♂️',
    description: `${i18n.t('emojis.list.man-in-lotus-position')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'lotus_position_man',
    ],
    tags: [
      'meditation',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🧘‍♀️',
    description: `${i18n.t('emojis.list.woman-in-lotus-position')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'lotus_position_woman',
    ],
    tags: [
      'meditation',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '🛀',
    description: `${i18n.t('emojis.list.person-taking-bath')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'bath',
    ],
    tags: [
      'shower',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '🛌',
    description: `${i18n.t('emojis.list.person-in-bed')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'sleeping_bed',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    emoji: '🧑‍🤝‍🧑',
    description: `${i18n.t('emojis.list.people-holding-hands')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'people_holding_hands',
    ],
    tags: [
      'couple',
      'date',
    ],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: false,
  },
  {
    emoji: '👭',
    description: `${i18n.t('emojis.list.women-holding-hands')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'two_women_holding_hands',
    ],
    tags: [
      'couple',
      'date',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '👫',
    description: `${i18n.t('emojis.list.woman-and-man-holding-hands')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'couple',
    ],
    tags: [
      'date',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '👬',
    description: `${i18n.t('emojis.list.men-holding-hands')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'two_men_holding_hands',
    ],
    tags: [
      'couple',
      'date',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '💏',
    description: `${i18n.t('emojis.list.kiss')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'couplekiss',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '👩‍❤️‍💋‍👨',
    description: `${i18n.t('emojis.list.kiss-woman-man')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'couplekiss_man_woman',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '👨‍❤️‍💋‍👨',
    description: `${i18n.t('emojis.list.kiss-man-man')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'couplekiss_man_man',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
    skin_tones: true,
  },
  {
    emoji: '👩‍❤️‍💋‍👩',
    description: `${i18n.t('emojis.list.kiss-woman-woman')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'couplekiss_woman_woman',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
    skin_tones: true,
  },
  {
    emoji: '💑',
    description: `${i18n.t('emojis.list.couple-with-heart')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'couple_with_heart',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    emoji: '👩‍❤️‍👨',
    description: `${i18n.t('emojis.list.couple-with-heart-woman-man')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'couple_with_heart_woman_man',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    emoji: '👨‍❤️‍👨',
    description: `${i18n.t('emojis.list.couple-with-heart-man-man')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'couple_with_heart_man_man',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
    skin_tones: true,
  },
  {
    emoji: '👩‍❤️‍👩',
    description: `${i18n.t('emojis.list.couple-with-heart-woman-woman')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'couple_with_heart_woman_woman',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
    skin_tones: true,
  },
  {
    emoji: '👪',
    description: `${i18n.t('emojis.list.family')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family',
    ],
    tags: [
      'home',
      'parents',
      'child',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👨‍👩‍👦',
    description: `${i18n.t('emojis.list.family-man-woman-boy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_man_woman_boy',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '👨‍👩‍👧',
    description: `${i18n.t('emojis.list.family-man-woman-girl')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_man_woman_girl',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '👨‍👩‍👧‍👦',
    description: `${i18n.t('emojis.list.family-man-woman-girl-boy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_man_woman_girl_boy',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '👨‍👩‍👦‍👦',
    description: `${i18n.t('emojis.list.family-man-woman-boy-boy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_man_woman_boy_boy',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '👨‍👩‍👧‍👧',
    description: `${i18n.t('emojis.list.family-man-woman-girl-girl')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_man_woman_girl_girl',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '👨‍👨‍👦',
    description: `${i18n.t('emojis.list.family-man-man-boy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_man_man_boy',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '👨‍👨‍👧',
    description: `${i18n.t('emojis.list.family-man-man-girl')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_man_man_girl',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '👨‍👨‍👧‍👦',
    description: `${i18n.t('emojis.list.family-man-man-girl-boy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_man_man_girl_boy',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '👨‍👨‍👦‍👦',
    description: `${i18n.t('emojis.list.family-man-man-boy-boy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_man_man_boy_boy',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '👨‍👨‍👧‍👧',
    description: `${i18n.t('emojis.list.family-man-man-girl-girl')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_man_man_girl_girl',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '👩‍👩‍👦',
    description: `${i18n.t('emojis.list.family-woman-woman-boy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_woman_woman_boy',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '👩‍👩‍👧',
    description: `${i18n.t('emojis.list.family-woman-woman-girl')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_woman_woman_girl',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '👩‍👩‍👧‍👦',
    description: `${i18n.t('emojis.list.family-woman-woman-girl-boy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_woman_woman_girl_boy',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '👩‍👩‍👦‍👦',
    description: `${i18n.t('emojis.list.family-woman-woman-boy-boy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_woman_woman_boy_boy',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '👩‍👩‍👧‍👧',
    description: `${i18n.t('emojis.list.family-woman-woman-girl-girl')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_woman_woman_girl_girl',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '👨‍👦',
    description: `${i18n.t('emojis.list.family-man-boy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_man_boy',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    emoji: '👨‍👦‍👦',
    description: `${i18n.t('emojis.list.family-man-boy-boy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_man_boy_boy',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    emoji: '👨‍👧',
    description: `${i18n.t('emojis.list.family-man-girl')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_man_girl',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    emoji: '👨‍👧‍👦',
    description: `${i18n.t('emojis.list.family-man-girl-boy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_man_girl_boy',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    emoji: '👨‍👧‍👧',
    description: `${i18n.t('emojis.list.family-man-girl-girl')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_man_girl_girl',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    emoji: '👩‍👦',
    description: `${i18n.t('emojis.list.family-woman-boy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_woman_boy',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    emoji: '👩‍👦‍👦',
    description: `${i18n.t('emojis.list.family-woman-boy-boy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_woman_boy_boy',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    emoji: '👩‍👧',
    description: `${i18n.t('emojis.list.family-woman-girl')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_woman_girl',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    emoji: '👩‍👧‍👦',
    description: `${i18n.t('emojis.list.family-woman-girl-boy')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_woman_girl_boy',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    emoji: '👩‍👧‍👧',
    description: `${i18n.t('emojis.list.family-woman-girl-girl')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'family_woman_girl_girl',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    emoji: '🗣️',
    description: `${i18n.t('emojis.list.speaking-head')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'speaking_head',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '👤',
    description: `${i18n.t('emojis.list.bust-in-silhouette')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'bust_in_silhouette',
    ],
    tags: [
      'user',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👥',
    description: `${i18n.t('emojis.list.busts-in-silhouette')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'busts_in_silhouette',
    ],
    tags: [
      'users',
      'group',
      'team',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🫂',
    description: `${i18n.t('emojis.list.people-hugging')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'people_hugging',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '👣',
    description: `${i18n.t('emojis.list.footprints')}`,
    category: `${i18n.t('emojis.categories.people-body')}`,
    aliases: [
      'footprints',
    ],
    tags: [
      'feet',
      'tracks',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐵',
    description: `${i18n.t('emojis.list.monkey-face')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'monkey_face',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐒',
    description: `${i18n.t('emojis.list.monkey')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'monkey',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🦍',
    description: `${i18n.t('emojis.list.gorilla')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'gorilla',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🦧',
    description: `${i18n.t('emojis.list.orangutan')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'orangutan',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🐶',
    description: `${i18n.t('emojis.list.dog-face')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'dog',
    ],
    tags: [
      'pet',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐕',
    description: `${i18n.t('emojis.list.dog')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'dog2',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🦮',
    description: `${i18n.t('emojis.list.guide-dog')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'guide_dog',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🐕‍🦺',
    description: `${i18n.t('emojis.list.service-dog')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'service_dog',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🐩',
    description: `${i18n.t('emojis.list.poodle')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'poodle',
    ],
    tags: [
      'dog',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐺',
    description: `${i18n.t('emojis.list.wolf')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'wolf',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🦊',
    description: `${i18n.t('emojis.list.fox')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'fox_face',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🦝',
    description: `${i18n.t('emojis.list.raccoon')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'raccoon',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🐱',
    description: `${i18n.t('emojis.list.cat-face')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'cat',
    ],
    tags: [
      'pet',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐈',
    description: `${i18n.t('emojis.list.cat')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'cat2',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐈‍⬛',
    description: `${i18n.t('emojis.list.black-cat')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'black_cat',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🦁',
    description: `${i18n.t('emojis.list.lion')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'lion',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🐯',
    description: `${i18n.t('emojis.list.tiger-face')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'tiger',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐅',
    description: `${i18n.t('emojis.list.tiger')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'tiger2',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐆',
    description: `${i18n.t('emojis.list.leopard')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'leopard',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐴',
    description: `${i18n.t('emojis.list.horse-face')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'horse',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐎',
    description: `${i18n.t('emojis.list.horse')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'racehorse',
    ],
    tags: [
      'speed',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🦄',
    description: `${i18n.t('emojis.list.unicorn')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'unicorn',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🦓',
    description: `${i18n.t('emojis.list.zebra')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'zebra',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🦌',
    description: `${i18n.t('emojis.list.deer')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'deer',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🦬',
    description: `${i18n.t('emojis.list.bison')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'bison',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🐮',
    description: `${i18n.t('emojis.list.cow-face')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'cow',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐂',
    description: `${i18n.t('emojis.list.ox')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'ox',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐃',
    description: `${i18n.t('emojis.list.water-buffalo')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'water_buffalo',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐄',
    description: `${i18n.t('emojis.list.cow')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'cow2',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐷',
    description: `${i18n.t('emojis.list.pig-face')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'pig',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐖',
    description: `${i18n.t('emojis.list.pig')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'pig2',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐗',
    description: `${i18n.t('emojis.list.boar')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'boar',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐽',
    description: `${i18n.t('emojis.list.pig-nose')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'pig_nose',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐏',
    description: `${i18n.t('emojis.list.ram')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'ram',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐑',
    description: `${i18n.t('emojis.list.ewe')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'sheep',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐐',
    description: `${i18n.t('emojis.list.goat')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'goat',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐪',
    description: `${i18n.t('emojis.list.camel')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'dromedary_camel',
    ],
    tags: [
      'desert',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐫',
    description: `${i18n.t('emojis.list.two-hump-camel')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'camel',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🦙',
    description: `${i18n.t('emojis.list.llama')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'llama',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🦒',
    description: `${i18n.t('emojis.list.giraffe')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'giraffe',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🐘',
    description: `${i18n.t('emojis.list.elephant')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'elephant',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🦣',
    description: `${i18n.t('emojis.list.mammoth')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'mammoth',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🦏',
    description: `${i18n.t('emojis.list.rhinoceros')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'rhinoceros',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🦛',
    description: `${i18n.t('emojis.list.hippopotamus')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'hippopotamus',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🐭',
    description: `${i18n.t('emojis.list.mouse-face')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'mouse',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐁',
    description: `${i18n.t('emojis.list.mouse')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'mouse2',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐀',
    description: `${i18n.t('emojis.list.rat')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'rat',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐹',
    description: `${i18n.t('emojis.list.hamster')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'hamster',
    ],
    tags: [
      'pet',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐰',
    description: `${i18n.t('emojis.list.rabbit-face')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'rabbit',
    ],
    tags: [
      'bunny',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐇',
    description: `${i18n.t('emojis.list.rabbit')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'rabbit2',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐿️',
    description: `${i18n.t('emojis.list.chipmunk')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'chipmunk',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🦫',
    description: `${i18n.t('emojis.list.beaver')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'beaver',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🦔',
    description: `${i18n.t('emojis.list.hedgehog')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'hedgehog',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🦇',
    description: `${i18n.t('emojis.list.bat')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'bat',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🐻',
    description: `${i18n.t('emojis.list.bear')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'bear',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐻‍❄️',
    description: `${i18n.t('emojis.list.polar-bear')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'polar_bear',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🐨',
    description: `${i18n.t('emojis.list.koala')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'koala',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐼',
    description: `${i18n.t('emojis.list.panda')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'panda_face',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🦥',
    description: `${i18n.t('emojis.list.sloth')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'sloth',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🦦',
    description: `${i18n.t('emojis.list.otter')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'otter',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🦨',
    description: `${i18n.t('emojis.list.skunk')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'skunk',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🦘',
    description: `${i18n.t('emojis.list.kangaroo')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'kangaroo',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🦡',
    description: `${i18n.t('emojis.list.badger')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'badger',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🐾',
    description: `${i18n.t('emojis.list.paw-prints')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'feet',
      'paw_prints',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🦃',
    description: `${i18n.t('emojis.list.turkey')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'turkey',
    ],
    tags: [
      'thanksgiving',
    ],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🐔',
    description: `${i18n.t('emojis.list.chicken')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'chicken',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐓',
    description: `${i18n.t('emojis.list.rooster')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'rooster',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐣',
    description: `${i18n.t('emojis.list.hatching-chick')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'hatching_chick',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐤',
    description: `${i18n.t('emojis.list.baby-chick')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'baby_chick',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐥',
    description: `${i18n.t('emojis.list.front-facing-baby-chick')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'hatched_chick',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐦',
    description: `${i18n.t('emojis.list.bird')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'bird',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐧',
    description: `${i18n.t('emojis.list.penguin')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'penguin',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕊️',
    description: `${i18n.t('emojis.list.dove')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'dove',
    ],
    tags: [
      'peace',
    ],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🦅',
    description: `${i18n.t('emojis.list.eagle')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'eagle',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🦆',
    description: `${i18n.t('emojis.list.duck')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'duck',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🦢',
    description: `${i18n.t('emojis.list.swan')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'swan',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🦉',
    description: `${i18n.t('emojis.list.owl')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'owl',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🦤',
    description: `${i18n.t('emojis.list.dodo')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'dodo',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🪶',
    description: `${i18n.t('emojis.list.feather')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'feather',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🦩',
    description: `${i18n.t('emojis.list.flamingo')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'flamingo',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🦚',
    description: `${i18n.t('emojis.list.peacock')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'peacock',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🦜',
    description: `${i18n.t('emojis.list.parrot')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'parrot',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🐸',
    description: `${i18n.t('emojis.list.frog')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'frog',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐊',
    description: `${i18n.t('emojis.list.crocodile')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'crocodile',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐢',
    description: `${i18n.t('emojis.list.turtle')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'turtle',
    ],
    tags: [
      'slow',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🦎',
    description: `${i18n.t('emojis.list.lizard')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'lizard',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🐍',
    description: `${i18n.t('emojis.list.snake')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'snake',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐲',
    description: `${i18n.t('emojis.list.dragon-face')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'dragon_face',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐉',
    description: `${i18n.t('emojis.list.dragon')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'dragon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🦕',
    description: `${i18n.t('emojis.list.sauropod')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'sauropod',
    ],
    tags: [
      'dinosaur',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🦖',
    description: `${i18n.t('emojis.list.t-rex')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      't-rex',
    ],
    tags: [
      'dinosaur',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🐳',
    description: `${i18n.t('emojis.list.spouting-whale')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'whale',
    ],
    tags: [
      'sea',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐋',
    description: `${i18n.t('emojis.list.whale')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'whale2',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐬',
    description: `${i18n.t('emojis.list.dolphin')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'dolphin',
      'flipper',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🦭',
    description: `${i18n.t('emojis.list.seal')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'seal',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🐟',
    description: `${i18n.t('emojis.list.fish')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'fish',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐠',
    description: `${i18n.t('emojis.list.tropical-fish')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'tropical_fish',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐡',
    description: `${i18n.t('emojis.list.blowfish')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'blowfish',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🦈',
    description: `${i18n.t('emojis.list.shark')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'shark',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🐙',
    description: `${i18n.t('emojis.list.octopus')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'octopus',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐚',
    description: `${i18n.t('emojis.list.spiral-shell')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'shell',
    ],
    tags: [
      'sea',
      'beach',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪸',
    description: `${i18n.t('emojis.list.coral')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'coral',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🐌',
    description: `${i18n.t('emojis.list.snail')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'snail',
    ],
    tags: [
      'slow',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🦋',
    description: `${i18n.t('emojis.list.butterfly')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'butterfly',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🐛',
    description: `${i18n.t('emojis.list.bug')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'bug',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐜',
    description: `${i18n.t('emojis.list.ant')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'ant',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🐝',
    description: `${i18n.t('emojis.list.honeybee')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'bee',
      'honeybee',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪲',
    description: `${i18n.t('emojis.list.beetle')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'beetle',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🐞',
    description: `${i18n.t('emojis.list.lady-beetle')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'lady_beetle',
    ],
    tags: [
      'bug',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🦗',
    description: `${i18n.t('emojis.list.cricket')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'cricket',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🪳',
    description: `${i18n.t('emojis.list.cockroach')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'cockroach',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🕷️',
    description: `${i18n.t('emojis.list.spider')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'spider',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🕸️',
    description: `${i18n.t('emojis.list.spider-web')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'spider_web',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🦂',
    description: `${i18n.t('emojis.list.scorpion')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'scorpion',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🦟',
    description: `${i18n.t('emojis.list.mosquito')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'mosquito',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🪰',
    description: `${i18n.t('emojis.list.fly')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'fly',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🪱',
    description: `${i18n.t('emojis.list.worm')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'worm',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🦠',
    description: `${i18n.t('emojis.list.microbe')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'microbe',
    ],
    tags: [
      'germ',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '💐',
    description: `${i18n.t('emojis.list.bouquet')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'bouquet',
    ],
    tags: [
      'flowers',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌸',
    description: `${i18n.t('emojis.list.cherry-blossom')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'cherry_blossom',
    ],
    tags: [
      'flower',
      'spring',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💮',
    description: `${i18n.t('emojis.list.white-flower')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'white_flower',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪷',
    description: `${i18n.t('emojis.list.lotus')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'lotus',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🏵️',
    description: `${i18n.t('emojis.list.rosette')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'rosette',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🌹',
    description: `${i18n.t('emojis.list.rose')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'rose',
    ],
    tags: [
      'flower',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥀',
    description: `${i18n.t('emojis.list.wilted-flower')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'wilted_flower',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🌺',
    description: `${i18n.t('emojis.list.hibiscus')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'hibiscus',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌻',
    description: `${i18n.t('emojis.list.sunflower')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'sunflower',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌼',
    description: `${i18n.t('emojis.list.blossom')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'blossom',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌷',
    description: `${i18n.t('emojis.list.tulip')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'tulip',
    ],
    tags: [
      'flower',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌱',
    description: `${i18n.t('emojis.list.seedling')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'seedling',
    ],
    tags: [
      'plant',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪴',
    description: `${i18n.t('emojis.list.potted-plant')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'potted_plant',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🌲',
    description: `${i18n.t('emojis.list.evergreen-tree')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'evergreen_tree',
    ],
    tags: [
      'wood',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌳',
    description: `${i18n.t('emojis.list.deciduous-tree')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'deciduous_tree',
    ],
    tags: [
      'wood',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌴',
    description: `${i18n.t('emojis.list.palm-tree')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'palm_tree',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌵',
    description: `${i18n.t('emojis.list.cactus')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'cactus',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌾',
    description: `${i18n.t('emojis.list.sheaf-of-rice')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'ear_of_rice',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌿',
    description: `${i18n.t('emojis.list.herb')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'herb',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '☘️',
    description: `${i18n.t('emojis.list.shamrock')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'shamrock',
    ],
    tags: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    emoji: '🍀',
    description: `${i18n.t('emojis.list.four-leaf-clover')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'four_leaf_clover',
    ],
    tags: [
      'luck',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍁',
    description: `${i18n.t('emojis.list.maple-leaf')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'maple_leaf',
    ],
    tags: [
      'canada',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍂',
    description: `${i18n.t('emojis.list.fallen-leaf')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'fallen_leaf',
    ],
    tags: [
      'autumn',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍃',
    description: `${i18n.t('emojis.list.leaf-fluttering-in-wind')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'leaves',
    ],
    tags: [
      'leaf',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪹',
    description: `${i18n.t('emojis.list.empty-nest')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'empty_nest',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🪺',
    description: `${i18n.t('emojis.list.nest-with-eggs')}`,
    category: `${i18n.t('emojis.categories.animals-nature')}`,
    aliases: [
      'nest_with_eggs',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🍇',
    description: `${i18n.t('emojis.list.grapes')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'grapes',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍈',
    description: `${i18n.t('emojis.list.melon')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'melon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍉',
    description: `${i18n.t('emojis.list.watermelon')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'watermelon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍊',
    description: `${i18n.t('emojis.list.tangerine')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'tangerine',
      'orange',
      'mandarin',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍋',
    description: `${i18n.t('emojis.list.lemon')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'lemon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍌',
    description: `${i18n.t('emojis.list.banana')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'banana',
    ],
    tags: [
      'fruit',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍍',
    description: `${i18n.t('emojis.list.pineapple')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'pineapple',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥭',
    description: `${i18n.t('emojis.list.mango')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'mango',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🍎',
    description: `${i18n.t('emojis.list.red-apple')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'apple',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍏',
    description: `${i18n.t('emojis.list.green-apple')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'green_apple',
    ],
    tags: [
      'fruit',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍐',
    description: `${i18n.t('emojis.list.pear')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'pear',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍑',
    description: `${i18n.t('emojis.list.peach')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'peach',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍒',
    description: `${i18n.t('emojis.list.cherries')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'cherries',
    ],
    tags: [
      'fruit',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍓',
    description: `${i18n.t('emojis.list.strawberry')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'strawberry',
    ],
    tags: [
      'fruit',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🫐',
    description: `${i18n.t('emojis.list.blueberries')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'blueberries',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🥝',
    description: `${i18n.t('emojis.list.kiwi-fruit')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'kiwi_fruit',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🍅',
    description: `${i18n.t('emojis.list.tomato')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'tomato',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🫒',
    description: `${i18n.t('emojis.list.olive')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'olive',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🥥',
    description: `${i18n.t('emojis.list.coconut')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'coconut',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🥑',
    description: `${i18n.t('emojis.list.avocado')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'avocado',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🍆',
    description: `${i18n.t('emojis.list.eggplant')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'eggplant',
    ],
    tags: [
      'aubergine',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥔',
    description: `${i18n.t('emojis.list.potato')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'potato',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🥕',
    description: `${i18n.t('emojis.list.carrot')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'carrot',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🌽',
    description: `${i18n.t('emojis.list.ear-of-corn')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'corn',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌶️',
    description: `${i18n.t('emojis.list.hot-pepper')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'hot_pepper',
    ],
    tags: [
      'spicy',
    ],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🫑',
    description: `${i18n.t('emojis.list.bell-pepper')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'bell_pepper',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🥒',
    description: `${i18n.t('emojis.list.cucumber')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'cucumber',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🥬',
    description: `${i18n.t('emojis.list.leafy-green')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'leafy_green',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🥦',
    description: `${i18n.t('emojis.list.broccoli')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'broccoli',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧄',
    description: `${i18n.t('emojis.list.garlic')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'garlic',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🧅',
    description: `${i18n.t('emojis.list.onion')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'onion',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🍄',
    description: `${i18n.t('emojis.list.mushroom')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'mushroom',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥜',
    description: `${i18n.t('emojis.list.peanuts')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'peanuts',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🫘',
    description: `${i18n.t('emojis.list.beans')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'beans',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🌰',
    description: `${i18n.t('emojis.list.chestnut')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'chestnut',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍞',
    description: `${i18n.t('emojis.list.bread')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'bread',
    ],
    tags: [
      'toast',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥐',
    description: `${i18n.t('emojis.list.croissant')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'croissant',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🥖',
    description: `${i18n.t('emojis.list.baguette-bread')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'baguette_bread',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🫓',
    description: `${i18n.t('emojis.list.flatbread')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'flatbread',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🥨',
    description: `${i18n.t('emojis.list.pretzel')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'pretzel',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🥯',
    description: `${i18n.t('emojis.list.bagel')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'bagel',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🥞',
    description: `${i18n.t('emojis.list.pancakes')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'pancakes',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🧇',
    description: `${i18n.t('emojis.list.waffle')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'waffle',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🧀',
    description: `${i18n.t('emojis.list.cheese-wedge')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'cheese',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🍖',
    description: `${i18n.t('emojis.list.meat-on-bone')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'meat_on_bone',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍗',
    description: `${i18n.t('emojis.list.poultry-leg')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'poultry_leg',
    ],
    tags: [
      'meat',
      'chicken',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥩',
    description: `${i18n.t('emojis.list.cut-of-meat')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'cut_of_meat',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🥓',
    description: `${i18n.t('emojis.list.bacon')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'bacon',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🍔',
    description: `${i18n.t('emojis.list.hamburger')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'hamburger',
    ],
    tags: [
      'burger',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍟',
    description: `${i18n.t('emojis.list.french-fries')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'fries',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍕',
    description: `${i18n.t('emojis.list.pizza')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'pizza',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌭',
    description: `${i18n.t('emojis.list.hot-dog')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'hotdog',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🥪',
    description: `${i18n.t('emojis.list.sandwich')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'sandwich',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🌮',
    description: `${i18n.t('emojis.list.taco')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'taco',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🌯',
    description: `${i18n.t('emojis.list.burrito')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'burrito',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🫔',
    description: `${i18n.t('emojis.list.tamale')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'tamale',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🥙',
    description: `${i18n.t('emojis.list.stuffed-flatbread')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'stuffed_flatbread',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🧆',
    description: `${i18n.t('emojis.list.falafel')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'falafel',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🥚',
    description: `${i18n.t('emojis.list.egg')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'egg',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🍳',
    description: `${i18n.t('emojis.list.cooking')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'fried_egg',
    ],
    tags: [
      'breakfast',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥘',
    description: `${i18n.t('emojis.list.shallow-pan-of-food')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'shallow_pan_of_food',
    ],
    tags: [
      'paella',
      'curry',
    ],
    unicode_version: '',
    ios_version: '10.2',
  },
  {
    emoji: '🍲',
    description: `${i18n.t('emojis.list.pot-of-food')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'stew',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🫕',
    description: `${i18n.t('emojis.list.fondue')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'fondue',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🥣',
    description: `${i18n.t('emojis.list.bowl-with-spoon')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'bowl_with_spoon',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🥗',
    description: `${i18n.t('emojis.list.green-salad')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'green_salad',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🍿',
    description: `${i18n.t('emojis.list.popcorn')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'popcorn',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🧈',
    description: `${i18n.t('emojis.list.butter')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'butter',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🧂',
    description: `${i18n.t('emojis.list.salt')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'salt',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🥫',
    description: `${i18n.t('emojis.list.canned-food')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'canned_food',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🍱',
    description: `${i18n.t('emojis.list.bento-box')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'bento',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍘',
    description: `${i18n.t('emojis.list.rice-cracker')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'rice_cracker',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍙',
    description: `${i18n.t('emojis.list.rice-ball')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'rice_ball',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍚',
    description: `${i18n.t('emojis.list.cooked-rice')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'rice',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍛',
    description: `${i18n.t('emojis.list.curry-rice')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'curry',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍜',
    description: `${i18n.t('emojis.list.steaming-bowl')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'ramen',
    ],
    tags: [
      'noodle',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍝',
    description: `${i18n.t('emojis.list.spaghetti')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'spaghetti',
    ],
    tags: [
      'pasta',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍠',
    description: `${i18n.t('emojis.list.roasted-sweet-potato')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'sweet_potato',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍢',
    description: `${i18n.t('emojis.list.oden')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'oden',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍣',
    description: `${i18n.t('emojis.list.sushi')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'sushi',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍤',
    description: `${i18n.t('emojis.list.fried-shrimp')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'fried_shrimp',
    ],
    tags: [
      'tempura',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍥',
    description: `${i18n.t('emojis.list.fish-cake-with-swirl')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'fish_cake',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥮',
    description: `${i18n.t('emojis.list.moon-cake')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'moon_cake',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🍡',
    description: `${i18n.t('emojis.list.dango')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'dango',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥟',
    description: `${i18n.t('emojis.list.dumpling')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'dumpling',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🥠',
    description: `${i18n.t('emojis.list.fortune-cookie')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'fortune_cookie',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🥡',
    description: `${i18n.t('emojis.list.takeout-box')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'takeout_box',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🦀',
    description: `${i18n.t('emojis.list.crab')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'crab',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🦞',
    description: `${i18n.t('emojis.list.lobster')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'lobster',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🦐',
    description: `${i18n.t('emojis.list.shrimp')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'shrimp',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🦑',
    description: `${i18n.t('emojis.list.squid')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'squid',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🦪',
    description: `${i18n.t('emojis.list.oyster')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'oyster',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🍦',
    description: `${i18n.t('emojis.list.soft-ice-cream')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'icecream',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍧',
    description: `${i18n.t('emojis.list.shaved-ice')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'shaved_ice',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍨',
    description: `${i18n.t('emojis.list.ice-cream')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'ice_cream',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍩',
    description: `${i18n.t('emojis.list.doughnut')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'doughnut',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍪',
    description: `${i18n.t('emojis.list.cookie')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'cookie',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎂',
    description: `${i18n.t('emojis.list.birthday-cake')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'birthday',
    ],
    tags: [
      'party',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍰',
    description: `${i18n.t('emojis.list.shortcake')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'cake',
    ],
    tags: [
      'dessert',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🧁',
    description: `${i18n.t('emojis.list.cupcake')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'cupcake',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🥧',
    description: `${i18n.t('emojis.list.pie')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'pie',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🍫',
    description: `${i18n.t('emojis.list.chocolate-bar')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'chocolate_bar',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍬',
    description: `${i18n.t('emojis.list.candy')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'candy',
    ],
    tags: [
      'sweet',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍭',
    description: `${i18n.t('emojis.list.lollipop')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'lollipop',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍮',
    description: `${i18n.t('emojis.list.custard')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'custard',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍯',
    description: `${i18n.t('emojis.list.honey-pot')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'honey_pot',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍼',
    description: `${i18n.t('emojis.list.baby-bottle')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'baby_bottle',
    ],
    tags: [
      'milk',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥛',
    description: `${i18n.t('emojis.list.glass-of-milk')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'milk_glass',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '☕',
    description: `${i18n.t('emojis.list.hot-beverage')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'coffee',
    ],
    tags: [
      'cafe',
      'espresso',
    ],
    unicode_version: '4.0',
    ios_version: '6.0',
  },
  {
    emoji: '🫖',
    description: `${i18n.t('emojis.list.teapot')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'teapot',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🍵',
    description: `${i18n.t('emojis.list.teacup-without-handle')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'tea',
    ],
    tags: [
      'green',
      'breakfast',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍶',
    description: `${i18n.t('emojis.list.sake')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'sake',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍾',
    description: `${i18n.t('emojis.list.bottle-with-popping-cork')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'champagne',
    ],
    tags: [
      'bottle',
      'bubbly',
      'celebration',
    ],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🍷',
    description: `${i18n.t('emojis.list.wine-glass')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'wine_glass',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍸',
    description: `${i18n.t('emojis.list.cocktail-glass')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'cocktail',
    ],
    tags: [
      'drink',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍹',
    description: `${i18n.t('emojis.list.tropical-drink')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'tropical_drink',
    ],
    tags: [
      'summer',
      'vacation',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍺',
    description: `${i18n.t('emojis.list.beer-mug')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'beer',
    ],
    tags: [
      'drink',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🍻',
    description: `${i18n.t('emojis.list.clinking-beer-mugs')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'beers',
    ],
    tags: [
      'drinks',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥂',
    description: `${i18n.t('emojis.list.clinking-glasses')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'clinking_glasses',
    ],
    tags: [
      'cheers',
      'toast',
    ],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🥃',
    description: `${i18n.t('emojis.list.tumbler-glass')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'tumbler_glass',
    ],
    tags: [
      'whisky',
    ],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🫗',
    description: `${i18n.t('emojis.list.pouring-liquid')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'pouring_liquid',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🥤',
    description: `${i18n.t('emojis.list.cup-with-straw')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'cup_with_straw',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧋',
    description: `${i18n.t('emojis.list.bubble-tea')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'bubble_tea',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🧃',
    description: `${i18n.t('emojis.list.beverage-box')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'beverage_box',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🧉',
    description: `${i18n.t('emojis.list.mate')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'mate',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🧊',
    description: `${i18n.t('emojis.list.ice')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'ice_cube',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🥢',
    description: `${i18n.t('emojis.list.chopsticks')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'chopsticks',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🍽️',
    description: `${i18n.t('emojis.list.fork-and-knife-with-plate')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'plate_with_cutlery',
    ],
    tags: [
      'dining',
      'dinner',
    ],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🍴',
    description: `${i18n.t('emojis.list.fork-and-knife')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'fork_and_knife',
    ],
    tags: [
      'cutlery',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥄',
    description: `${i18n.t('emojis.list.spoon')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'spoon',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🔪',
    description: `${i18n.t('emojis.list.kitchen-knife')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'hocho',
      'knife',
    ],
    tags: [
      'cut',
      'chop',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🫙',
    description: `${i18n.t('emojis.list.jar')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'jar',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🏺',
    description: `${i18n.t('emojis.list.amphora')}`,
    category: `${i18n.t('emojis.categories.food-drink')}`,
    aliases: [
      'amphora',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🌍',
    description: `${i18n.t('emojis.list.globe-showing-europe-africa')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'earth_africa',
    ],
    tags: [
      'globe',
      'world',
      'international',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌎',
    description: `${i18n.t('emojis.list.globe-showing-americas')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'earth_americas',
    ],
    tags: [
      'globe',
      'world',
      'international',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌏',
    description: `${i18n.t('emojis.list.globe-showing-asia-australia')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'earth_asia',
    ],
    tags: [
      'globe',
      'world',
      'international',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌐',
    description: `${i18n.t('emojis.list.globe-with-meridians')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'globe_with_meridians',
    ],
    tags: [
      'world',
      'global',
      'international',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🗺️',
    description: `${i18n.t('emojis.list.world-map')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'world_map',
    ],
    tags: [
      'travel',
    ],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🗾',
    description: `${i18n.t('emojis.list.map-of-japan')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'japan',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🧭',
    description: `${i18n.t('emojis.list.compass')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'compass',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🏔️',
    description: `${i18n.t('emojis.list.snow-capped-mountain')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'mountain_snow',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '⛰️',
    description: `${i18n.t('emojis.list.mountain')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'mountain',
    ],
    tags: [],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    emoji: '🌋',
    description: `${i18n.t('emojis.list.volcano')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'volcano',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🗻',
    description: `${i18n.t('emojis.list.mount-fuji')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'mount_fuji',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏕️',
    description: `${i18n.t('emojis.list.camping')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'camping',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🏖️',
    description: `${i18n.t('emojis.list.beach-with-umbrella')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'beach_umbrella',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🏜️',
    description: `${i18n.t('emojis.list.desert')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'desert',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🏝️',
    description: `${i18n.t('emojis.list.desert-island')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'desert_island',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🏞️',
    description: `${i18n.t('emojis.list.national-park')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'national_park',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🏟️',
    description: `${i18n.t('emojis.list.stadium')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'stadium',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🏛️',
    description: `${i18n.t('emojis.list.classical-building')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'classical_building',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🏗️',
    description: `${i18n.t('emojis.list.building-construction')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'building_construction',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🧱',
    description: `${i18n.t('emojis.list.brick')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'bricks',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🪨',
    description: `${i18n.t('emojis.list.rock')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'rock',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🪵',
    description: `${i18n.t('emojis.list.wood')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'wood',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🛖',
    description: `${i18n.t('emojis.list.hut')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'hut',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🏘️',
    description: `${i18n.t('emojis.list.houses')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'houses',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🏚️',
    description: `${i18n.t('emojis.list.derelict-house')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'derelict_house',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🏠',
    description: `${i18n.t('emojis.list.house')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'house',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏡',
    description: `${i18n.t('emojis.list.house-with-garden')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'house_with_garden',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏢',
    description: `${i18n.t('emojis.list.office-building')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'office',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏣',
    description: `${i18n.t('emojis.list.japanese-post-office')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'post_office',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏤',
    description: `${i18n.t('emojis.list.post-office')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'european_post_office',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏥',
    description: `${i18n.t('emojis.list.hospital')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'hospital',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏦',
    description: `${i18n.t('emojis.list.bank')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'bank',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏨',
    description: `${i18n.t('emojis.list.hotel')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'hotel',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏩',
    description: `${i18n.t('emojis.list.love-hotel')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'love_hotel',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏪',
    description: `${i18n.t('emojis.list.convenience-store')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'convenience_store',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏫',
    description: `${i18n.t('emojis.list.school')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'school',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏬',
    description: `${i18n.t('emojis.list.department-store')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'department_store',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏭',
    description: `${i18n.t('emojis.list.factory')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'factory',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏯',
    description: `${i18n.t('emojis.list.japanese-castle')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'japanese_castle',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏰',
    description: `${i18n.t('emojis.list.castle')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'european_castle',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💒',
    description: `${i18n.t('emojis.list.wedding')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'wedding',
    ],
    tags: [
      'marriage',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🗼',
    description: `${i18n.t('emojis.list.tokyo-tower')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'tokyo_tower',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🗽',
    description: `${i18n.t('emojis.list.statue-of-liberty')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'statue_of_liberty',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '⛪',
    description: `${i18n.t('emojis.list.church')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'church',
    ],
    tags: [],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    emoji: '🕌',
    description: `${i18n.t('emojis.list.mosque')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'mosque',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🛕',
    description: `${i18n.t('emojis.list.hindu-temple')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'hindu_temple',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🕍',
    description: `${i18n.t('emojis.list.synagogue')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'synagogue',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '⛩️',
    description: `${i18n.t('emojis.list.shinto-shrine')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'shinto_shrine',
    ],
    tags: [],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    emoji: '🕋',
    description: `${i18n.t('emojis.list.kaaba')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'kaaba',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '⛲',
    description: `${i18n.t('emojis.list.fountain')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'fountain',
    ],
    tags: [],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    emoji: '⛺',
    description: `${i18n.t('emojis.list.tent')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'tent',
    ],
    tags: [
      'camping',
    ],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    emoji: '🌁',
    description: `${i18n.t('emojis.list.foggy')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'foggy',
    ],
    tags: [
      'karl',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌃',
    description: `${i18n.t('emojis.list.night-with-stars')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'night_with_stars',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏙️',
    description: `${i18n.t('emojis.list.cityscape')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'cityscape',
    ],
    tags: [
      'skyline',
    ],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🌄',
    description: `${i18n.t('emojis.list.sunrise-over-mountains')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'sunrise_over_mountains',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌅',
    description: `${i18n.t('emojis.list.sunrise')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'sunrise',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌆',
    description: `${i18n.t('emojis.list.cityscape-at-dusk')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'city_sunset',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌇',
    description: `${i18n.t('emojis.list.sunset')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'city_sunrise',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌉',
    description: `${i18n.t('emojis.list.bridge-at-night')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'bridge_at_night',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '♨️',
    description: `${i18n.t('emojis.list.hot-springs')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'hotsprings',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '🎠',
    description: `${i18n.t('emojis.list.carousel-horse')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'carousel_horse',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🛝',
    description: `${i18n.t('emojis.list.playground-slide')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'playground_slide',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🎡',
    description: `${i18n.t('emojis.list.ferris-wheel')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'ferris_wheel',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎢',
    description: `${i18n.t('emojis.list.roller-coaster')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'roller_coaster',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💈',
    description: `${i18n.t('emojis.list.barber-pole')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'barber',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎪',
    description: `${i18n.t('emojis.list.circus-tent')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'circus_tent',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚂',
    description: `${i18n.t('emojis.list.locomotive')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'steam_locomotive',
    ],
    tags: [
      'train',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚃',
    description: `${i18n.t('emojis.list.railway-car')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'railway_car',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚄',
    description: `${i18n.t('emojis.list.high-speed-train')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'bullettrain_side',
    ],
    tags: [
      'train',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚅',
    description: `${i18n.t('emojis.list.bullet-train')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'bullettrain_front',
    ],
    tags: [
      'train',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚆',
    description: `${i18n.t('emojis.list.train')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'train2',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚇',
    description: `${i18n.t('emojis.list.metro')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'metro',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚈',
    description: `${i18n.t('emojis.list.light-rail')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'light_rail',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚉',
    description: `${i18n.t('emojis.list.station')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'station',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚊',
    description: `${i18n.t('emojis.list.tram')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'tram',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚝',
    description: `${i18n.t('emojis.list.monorail')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'monorail',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚞',
    description: `${i18n.t('emojis.list.mountain-railway')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'mountain_railway',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚋',
    description: `${i18n.t('emojis.list.tram-car')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'train',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚌',
    description: `${i18n.t('emojis.list.bus')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'bus',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚍',
    description: `${i18n.t('emojis.list.oncoming-bus')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'oncoming_bus',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚎',
    description: `${i18n.t('emojis.list.trolleybus')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'trolleybus',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚐',
    description: `${i18n.t('emojis.list.minibus')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'minibus',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚑',
    description: `${i18n.t('emojis.list.ambulance')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'ambulance',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚒',
    description: `${i18n.t('emojis.list.fire-engine')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'fire_engine',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚓',
    description: `${i18n.t('emojis.list.police-car')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'police_car',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚔',
    description: `${i18n.t('emojis.list.oncoming-police-car')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'oncoming_police_car',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚕',
    description: `${i18n.t('emojis.list.taxi')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'taxi',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚖',
    description: `${i18n.t('emojis.list.oncoming-taxi')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'oncoming_taxi',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚗',
    description: `${i18n.t('emojis.list.automobile')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'car',
      'red_car',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚘',
    description: `${i18n.t('emojis.list.oncoming-automobile')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'oncoming_automobile',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚙',
    description: `${i18n.t('emojis.list.sport-utility-vehicle')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'blue_car',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🛻',
    description: `${i18n.t('emojis.list.pickup-truck')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'pickup_truck',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🚚',
    description: `${i18n.t('emojis.list.delivery-truck')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'truck',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚛',
    description: `${i18n.t('emojis.list.articulated-lorry')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'articulated_lorry',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚜',
    description: `${i18n.t('emojis.list.tractor')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'tractor',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏎️',
    description: `${i18n.t('emojis.list.racing-car')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'racing_car',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🏍️',
    description: `${i18n.t('emojis.list.motorcycle')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'motorcycle',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🛵',
    description: `${i18n.t('emojis.list.motor-scooter')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'motor_scooter',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🦽',
    description: `${i18n.t('emojis.list.manual-wheelchair')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'manual_wheelchair',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🦼',
    description: `${i18n.t('emojis.list.motorized-wheelchair')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'motorized_wheelchair',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🛺',
    description: `${i18n.t('emojis.list.auto-rickshaw')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'auto_rickshaw',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🚲',
    description: `${i18n.t('emojis.list.bicycle')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'bike',
    ],
    tags: [
      'bicycle',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🛴',
    description: `${i18n.t('emojis.list.kick-scooter')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'kick_scooter',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🛹',
    description: `${i18n.t('emojis.list.skateboard')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'skateboard',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🛼',
    description: `${i18n.t('emojis.list.roller-skate')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'roller_skate',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🚏',
    description: `${i18n.t('emojis.list.bus-stop')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'busstop',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🛣️',
    description: `${i18n.t('emojis.list.motorway')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'motorway',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🛤️',
    description: `${i18n.t('emojis.list.railway-track')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'railway_track',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🛢️',
    description: `${i18n.t('emojis.list.oil-drum')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'oil_drum',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '⛽',
    description: `${i18n.t('emojis.list.fuel-pump')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'fuelpump',
    ],
    tags: [],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    emoji: '🛞',
    description: `${i18n.t('emojis.list.wheel')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'wheel',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🚨',
    description: `${i18n.t('emojis.list.police-car-light')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'rotating_light',
    ],
    tags: [
      '911',
      'emergency',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚥',
    description: `${i18n.t('emojis.list.horizontal-traffic-light')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'traffic_light',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚦',
    description: `${i18n.t('emojis.list.vertical-traffic-light')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'vertical_traffic_light',
    ],
    tags: [
      'semaphore',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🛑',
    description: `${i18n.t('emojis.list.stop-sign')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'stop_sign',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🚧',
    description: `${i18n.t('emojis.list.construction')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'construction',
    ],
    tags: [
      'wip',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '⚓',
    description: `${i18n.t('emojis.list.anchor')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'anchor',
    ],
    tags: [
      'ship',
    ],
    unicode_version: '4.1',
    ios_version: '6.0',
  },
  {
    emoji: '🛟',
    description: `${i18n.t('emojis.list.ring-buoy')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'ring_buoy',
    ],
    tags: [
      'life preserver',
    ],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '⛵',
    description: `${i18n.t('emojis.list.sailboat')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'boat',
      'sailboat',
    ],
    tags: [],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    emoji: '🛶',
    description: `${i18n.t('emojis.list.canoe')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'canoe',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🚤',
    description: `${i18n.t('emojis.list.speedboat')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'speedboat',
    ],
    tags: [
      'ship',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🛳️',
    description: `${i18n.t('emojis.list.passenger-ship')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'passenger_ship',
    ],
    tags: [
      'cruise',
    ],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '⛴️',
    description: `${i18n.t('emojis.list.ferry')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'ferry',
    ],
    tags: [],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    emoji: '🛥️',
    description: `${i18n.t('emojis.list.motor-boat')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'motor_boat',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🚢',
    description: `${i18n.t('emojis.list.ship')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'ship',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '✈️',
    description: `${i18n.t('emojis.list.airplane')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'airplane',
    ],
    tags: [
      'flight',
    ],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '🛩️',
    description: `${i18n.t('emojis.list.small-airplane')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'small_airplane',
    ],
    tags: [
      'flight',
    ],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🛫',
    description: `${i18n.t('emojis.list.airplane-departure')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'flight_departure',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🛬',
    description: `${i18n.t('emojis.list.airplane-arrival')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'flight_arrival',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🪂',
    description: `${i18n.t('emojis.list.parachute')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'parachute',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '💺',
    description: `${i18n.t('emojis.list.seat')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'seat',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚁',
    description: `${i18n.t('emojis.list.helicopter')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'helicopter',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚟',
    description: `${i18n.t('emojis.list.suspension-railway')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'suspension_railway',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚠',
    description: `${i18n.t('emojis.list.mountain-cableway')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'mountain_cableway',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚡',
    description: `${i18n.t('emojis.list.aerial-tramway')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'aerial_tramway',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🛰️',
    description: `${i18n.t('emojis.list.satellite')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'artificial_satellite',
    ],
    tags: [
      'orbit',
      'space',
    ],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🚀',
    description: `${i18n.t('emojis.list.rocket')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'rocket',
    ],
    tags: [
      'ship',
      'launch',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🛸',
    description: `${i18n.t('emojis.list.flying-saucer')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'flying_saucer',
    ],
    tags: [
      'ufo',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🛎️',
    description: `${i18n.t('emojis.list.bellhop-bell')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'bellhop_bell',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🧳',
    description: `${i18n.t('emojis.list.luggage')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'luggage',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '⌛',
    description: `${i18n.t('emojis.list.hourglass-done')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'hourglass',
    ],
    tags: [
      'time',
    ],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '⏳',
    description: `${i18n.t('emojis.list.hourglass-not-done')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'hourglass_flowing_sand',
    ],
    tags: [
      'time',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '⌚',
    description: `${i18n.t('emojis.list.watch')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'watch',
    ],
    tags: [
      'time',
    ],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '⏰',
    description: `${i18n.t('emojis.list.alarm-clock')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'alarm_clock',
    ],
    tags: [
      'morning',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '⏱️',
    description: `${i18n.t('emojis.list.stopwatch')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'stopwatch',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.1',
  },
  {
    emoji: '⏲️',
    description: `${i18n.t('emojis.list.timer-clock')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'timer_clock',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.1',
  },
  {
    emoji: '🕰️',
    description: `${i18n.t('emojis.list.mantelpiece-clock')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'mantelpiece_clock',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🕛',
    description: `${i18n.t('emojis.list.twelve-o-clock')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock12',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕧',
    description: `${i18n.t('emojis.list.twelve-thirty')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock1230',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕐',
    description: `${i18n.t('emojis.list.one-o-clock')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock1',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕜',
    description: `${i18n.t('emojis.list.one-thirty')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock130',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕑',
    description: `${i18n.t('emojis.list.two-o-clock')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock2',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕝',
    description: `${i18n.t('emojis.list.two-thirty')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock230',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕒',
    description: `${i18n.t('emojis.list.three-o-clock')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock3',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕞',
    description: `${i18n.t('emojis.list.three-thirty')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock330',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕓',
    description: `${i18n.t('emojis.list.four-o-clock')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock4',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕟',
    description: `${i18n.t('emojis.list.four-thirty')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock430',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕔',
    description: `${i18n.t('emojis.list.five-o-clock')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock5',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕠',
    description: `${i18n.t('emojis.list.five-thirty')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock530',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕕',
    description: `${i18n.t('emojis.list.six-o-clock')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock6',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕡',
    description: `${i18n.t('emojis.list.six-thirty')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock630',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕖',
    description: `${i18n.t('emojis.list.seven-o-clock')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock7',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕢',
    description: `${i18n.t('emojis.list.seven-thirty')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock730',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕗',
    description: `${i18n.t('emojis.list.eight-o-clock')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock8',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕣',
    description: `${i18n.t('emojis.list.eight-thirty')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock830',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕘',
    description: `${i18n.t('emojis.list.nine-o-clock')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock9',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕤',
    description: `${i18n.t('emojis.list.nine-thirty')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock930',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕙',
    description: `${i18n.t('emojis.list.ten-o-clock')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock10',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕥',
    description: `${i18n.t('emojis.list.ten-thirty')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock1030',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕚',
    description: `${i18n.t('emojis.list.eleven-o-clock')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock11',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕦',
    description: `${i18n.t('emojis.list.eleven-thirty')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'clock1130',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌑',
    description: `${i18n.t('emojis.list.new-moon')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'new_moon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌒',
    description: `${i18n.t('emojis.list.waxing-crescent-moon')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'waxing_crescent_moon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌓',
    description: `${i18n.t('emojis.list.first-quarter-moon')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'first_quarter_moon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌔',
    description: `${i18n.t('emojis.list.waxing-gibbous-moon')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'moon',
      'waxing_gibbous_moon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌕',
    description: `${i18n.t('emojis.list.full-moon')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'full_moon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌖',
    description: `${i18n.t('emojis.list.waning-gibbous-moon')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'waning_gibbous_moon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌗',
    description: `${i18n.t('emojis.list.last-quarter-moon')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'last_quarter_moon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌘',
    description: `${i18n.t('emojis.list.waning-crescent-moon')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'waning_crescent_moon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌙',
    description: `${i18n.t('emojis.list.crescent-moon')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'crescent_moon',
    ],
    tags: [
      'night',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌚',
    description: `${i18n.t('emojis.list.new-moon-face')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'new_moon_with_face',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌛',
    description: `${i18n.t('emojis.list.first-quarter-moon-face')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'first_quarter_moon_with_face',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌜',
    description: `${i18n.t('emojis.list.last-quarter-moon-face')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'last_quarter_moon_with_face',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌡️',
    description: `${i18n.t('emojis.list.thermometer')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'thermometer',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '☀️',
    description: `${i18n.t('emojis.list.sun')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'sunny',
    ],
    tags: [
      'weather',
    ],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '🌝',
    description: `${i18n.t('emojis.list.full-moon-face')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'full_moon_with_face',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌞',
    description: `${i18n.t('emojis.list.sun-with-face')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'sun_with_face',
    ],
    tags: [
      'summer',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪐',
    description: `${i18n.t('emojis.list.ringed-planet')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'ringed_planet',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '⭐',
    description: `${i18n.t('emojis.list.star')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'star',
    ],
    tags: [],
    unicode_version: '5.1',
    ios_version: '6.0',
  },
  {
    emoji: '🌟',
    description: `${i18n.t('emojis.list.glowing-star')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'star2',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌠',
    description: `${i18n.t('emojis.list.shooting-star')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'stars',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌌',
    description: `${i18n.t('emojis.list.milky-way')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'milky_way',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '☁️',
    description: `${i18n.t('emojis.list.cloud')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'cloud',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '⛅',
    description: `${i18n.t('emojis.list.sun-behind-cloud')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'partly_sunny',
    ],
    tags: [
      'weather',
      'cloud',
    ],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    emoji: '⛈️',
    description: `${i18n.t('emojis.list.cloud-with-lightning-and-rain')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'cloud_with_lightning_and_rain',
    ],
    tags: [],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    emoji: '🌤️',
    description: `${i18n.t('emojis.list.sun-behind-small-cloud')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'sun_behind_small_cloud',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🌥️',
    description: `${i18n.t('emojis.list.sun-behind-large-cloud')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'sun_behind_large_cloud',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🌦️',
    description: `${i18n.t('emojis.list.sun-behind-rain-cloud')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'sun_behind_rain_cloud',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🌧️',
    description: `${i18n.t('emojis.list.cloud-with-rain')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'cloud_with_rain',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🌨️',
    description: `${i18n.t('emojis.list.cloud-with-snow')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'cloud_with_snow',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🌩️',
    description: `${i18n.t('emojis.list.cloud-with-lightning')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'cloud_with_lightning',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🌪️',
    description: `${i18n.t('emojis.list.tornado')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'tornado',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🌫️',
    description: `${i18n.t('emojis.list.fog')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'fog',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🌬️',
    description: `${i18n.t('emojis.list.wind-face')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'wind_face',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🌀',
    description: `${i18n.t('emojis.list.cyclone')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'cyclone',
    ],
    tags: [
      'swirl',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌈',
    description: `${i18n.t('emojis.list.rainbow')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'rainbow',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌂',
    description: `${i18n.t('emojis.list.closed-umbrella')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'closed_umbrella',
    ],
    tags: [
      'weather',
      'rain',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '☂️',
    description: `${i18n.t('emojis.list.umbrella')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'open_umbrella',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    emoji: '☔',
    description: `${i18n.t('emojis.list.umbrella-with-rain-drops')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'umbrella',
    ],
    tags: [
      'rain',
      'weather',
    ],
    unicode_version: '4.0',
    ios_version: '6.0',
  },
  {
    emoji: '⛱️',
    description: `${i18n.t('emojis.list.umbrella-on-ground')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'parasol_on_ground',
    ],
    tags: [
      'beach_umbrella',
    ],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    emoji: '⚡',
    description: `${i18n.t('emojis.list.high-voltage')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'zap',
    ],
    tags: [
      'lightning',
      'thunder',
    ],
    unicode_version: '4.0',
    ios_version: '6.0',
  },
  {
    emoji: '❄️',
    description: `${i18n.t('emojis.list.snowflake')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'snowflake',
    ],
    tags: [
      'winter',
      'cold',
      'weather',
    ],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '☃️',
    description: `${i18n.t('emojis.list.snowman')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'snowman_with_snow',
    ],
    tags: [
      'winter',
      'christmas',
    ],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    emoji: '⛄',
    description: `${i18n.t('emojis.list.snowman-without-snow')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'snowman',
    ],
    tags: [
      'winter',
    ],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    emoji: '☄️',
    description: `${i18n.t('emojis.list.comet')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'comet',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    emoji: '🔥',
    description: `${i18n.t('emojis.list.fire')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'fire',
    ],
    tags: [
      'burn',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💧',
    description: `${i18n.t('emojis.list.droplet')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'droplet',
    ],
    tags: [
      'water',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🌊',
    description: `${i18n.t('emojis.list.water-wave')}`,
    category: `${i18n.t('emojis.categories.travel-places')}`,
    aliases: [
      'ocean',
    ],
    tags: [
      'sea',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎃',
    description: `${i18n.t('emojis.list.jack-o-lantern')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'jack_o_lantern',
    ],
    tags: [
      'halloween',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎄',
    description: `${i18n.t('emojis.list.christmas-tree')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'christmas_tree',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎆',
    description: `${i18n.t('emojis.list.fireworks')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'fireworks',
    ],
    tags: [
      'festival',
      'celebration',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎇',
    description: `${i18n.t('emojis.list.sparkler')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'sparkler',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🧨',
    description: `${i18n.t('emojis.list.firecracker')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'firecracker',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '✨',
    description: `${i18n.t('emojis.list.sparkles')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'sparkles',
    ],
    tags: [
      'shiny',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎈',
    description: `${i18n.t('emojis.list.balloon')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'balloon',
    ],
    tags: [
      'party',
      'birthday',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎉',
    description: `${i18n.t('emojis.list.party-popper')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'tada',
    ],
    tags: [
      'hooray',
      'party',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎊',
    description: `${i18n.t('emojis.list.confetti-ball')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'confetti_ball',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎋',
    description: `${i18n.t('emojis.list.tanabata-tree')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'tanabata_tree',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎍',
    description: `${i18n.t('emojis.list.pine-decoration')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'bamboo',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎎',
    description: `${i18n.t('emojis.list.japanese-dolls')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'dolls',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎏',
    description: `${i18n.t('emojis.list.carp-streamer')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'flags',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎐',
    description: `${i18n.t('emojis.list.wind-chime')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'wind_chime',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎑',
    description: `${i18n.t('emojis.list.moon-viewing-ceremony')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'rice_scene',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🧧',
    description: `${i18n.t('emojis.list.red-envelope')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'red_envelope',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🎀',
    description: `${i18n.t('emojis.list.ribbon')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'ribbon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎁',
    description: `${i18n.t('emojis.list.wrapped-gift')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'gift',
    ],
    tags: [
      'present',
      'birthday',
      'christmas',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎗️',
    description: `${i18n.t('emojis.list.reminder-ribbon')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'reminder_ribbon',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🎟️',
    description: `${i18n.t('emojis.list.admission-tickets')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'tickets',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🎫',
    description: `${i18n.t('emojis.list.ticket')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'ticket',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎖️',
    description: `${i18n.t('emojis.list.military-medal')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'medal_military',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🏆',
    description: `${i18n.t('emojis.list.trophy')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'trophy',
    ],
    tags: [
      'award',
      'contest',
      'winner',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏅',
    description: `${i18n.t('emojis.list.sports-medal')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'medal_sports',
    ],
    tags: [
      'gold',
      'winner',
    ],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🥇',
    description: `${i18n.t('emojis.list.1-st-place-medal')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      '1st_place_medal',
    ],
    tags: [
      'gold',
    ],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🥈',
    description: `${i18n.t('emojis.list.2-nd-place-medal')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      '2nd_place_medal',
    ],
    tags: [
      'silver',
    ],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🥉',
    description: `${i18n.t('emojis.list.3-rd-place-medal')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      '3rd_place_medal',
    ],
    tags: [
      'bronze',
    ],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '⚽',
    description: `${i18n.t('emojis.list.soccer-ball')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'soccer',
    ],
    tags: [
      'sports',
    ],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    emoji: '⚾',
    description: `${i18n.t('emojis.list.baseball')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'baseball',
    ],
    tags: [
      'sports',
    ],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    emoji: '🥎',
    description: `${i18n.t('emojis.list.softball')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'softball',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🏀',
    description: `${i18n.t('emojis.list.basketball')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'basketball',
    ],
    tags: [
      'sports',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏐',
    description: `${i18n.t('emojis.list.volleyball')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'volleyball',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🏈',
    description: `${i18n.t('emojis.list.american-football')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'football',
    ],
    tags: [
      'sports',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏉',
    description: `${i18n.t('emojis.list.rugby-football')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'rugby_football',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎾',
    description: `${i18n.t('emojis.list.tennis')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'tennis',
    ],
    tags: [
      'sports',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥏',
    description: `${i18n.t('emojis.list.flying-disc')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'flying_disc',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🎳',
    description: `${i18n.t('emojis.list.bowling')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'bowling',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏏',
    description: `${i18n.t('emojis.list.cricket-game')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'cricket_game',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🏑',
    description: `${i18n.t('emojis.list.field-hockey')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'field_hockey',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🏒',
    description: `${i18n.t('emojis.list.ice-hockey')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'ice_hockey',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🥍',
    description: `${i18n.t('emojis.list.lacrosse')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'lacrosse',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🏓',
    description: `${i18n.t('emojis.list.ping-pong')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'ping_pong',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🏸',
    description: `${i18n.t('emojis.list.badminton')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'badminton',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🥊',
    description: `${i18n.t('emojis.list.boxing-glove')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'boxing_glove',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🥋',
    description: `${i18n.t('emojis.list.martial-arts-uniform')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'martial_arts_uniform',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🥅',
    description: `${i18n.t('emojis.list.goal-net')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'goal_net',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '⛳',
    description: `${i18n.t('emojis.list.flag-in-hole')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'golf',
    ],
    tags: [],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    emoji: '⛸️',
    description: `${i18n.t('emojis.list.ice-skate')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'ice_skate',
    ],
    tags: [
      'skating',
    ],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    emoji: '🎣',
    description: `${i18n.t('emojis.list.fishing-pole')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'fishing_pole_and_fish',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🤿',
    description: `${i18n.t('emojis.list.diving-mask')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'diving_mask',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🎽',
    description: `${i18n.t('emojis.list.running-shirt')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'running_shirt_with_sash',
    ],
    tags: [
      'marathon',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎿',
    description: `${i18n.t('emojis.list.skis')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'ski',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🛷',
    description: `${i18n.t('emojis.list.sled')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'sled',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🥌',
    description: `${i18n.t('emojis.list.curling-stone')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'curling_stone',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🎯',
    description: `${i18n.t('emojis.list.bullseye')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'dart',
    ],
    tags: [
      'target',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪀',
    description: `${i18n.t('emojis.list.yo-yo')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'yo_yo',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🪁',
    description: `${i18n.t('emojis.list.kite')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'kite',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🎱',
    description: `${i18n.t('emojis.list.pool-8-ball')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      '8ball',
    ],
    tags: [
      'pool',
      'billiards',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔮',
    description: `${i18n.t('emojis.list.crystal-ball')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'crystal_ball',
    ],
    tags: [
      'fortune',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪄',
    description: `${i18n.t('emojis.list.magic-wand')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'magic_wand',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🧿',
    description: `${i18n.t('emojis.list.nazar-amulet')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'nazar_amulet',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🪬',
    description: `${i18n.t('emojis.list.hamsa')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'hamsa',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🎮',
    description: `${i18n.t('emojis.list.video-game')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'video_game',
    ],
    tags: [
      'play',
      'controller',
      'console',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕹️',
    description: `${i18n.t('emojis.list.joystick')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'joystick',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🎰',
    description: `${i18n.t('emojis.list.slot-machine')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'slot_machine',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎲',
    description: `${i18n.t('emojis.list.game-die')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'game_die',
    ],
    tags: [
      'dice',
      'gambling',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🧩',
    description: `${i18n.t('emojis.list.puzzle-piece')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'jigsaw',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧸',
    description: `${i18n.t('emojis.list.teddy-bear')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'teddy_bear',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🪅',
    description: `${i18n.t('emojis.list.pinata')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'pinata',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🪩',
    description: `${i18n.t('emojis.list.mirror-ball')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'mirror_ball',
    ],
    tags: [
      'disco',
      'party',
    ],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🪆',
    description: `${i18n.t('emojis.list.nesting-dolls')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'nesting_dolls',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '♠️',
    description: `${i18n.t('emojis.list.spade-suit')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'spades',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '♥️',
    description: `${i18n.t('emojis.list.heart-suit')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'hearts',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '♦️',
    description: `${i18n.t('emojis.list.diamond-suit')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'diamonds',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '♣️',
    description: `${i18n.t('emojis.list.club-suit')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'clubs',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '♟️',
    description: `${i18n.t('emojis.list.chess-pawn')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'chess_pawn',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🃏',
    description: `${i18n.t('emojis.list.joker')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'black_joker',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🀄',
    description: `${i18n.t('emojis.list.mahjong-red-dragon')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'mahjong',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '🎴',
    description: `${i18n.t('emojis.list.flower-playing-cards')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'flower_playing_cards',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎭',
    description: `${i18n.t('emojis.list.performing-arts')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'performing_arts',
    ],
    tags: [
      'theater',
      'drama',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🖼️',
    description: `${i18n.t('emojis.list.framed-picture')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'framed_picture',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🎨',
    description: `${i18n.t('emojis.list.artist-palette')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'art',
    ],
    tags: [
      'design',
      'paint',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🧵',
    description: `${i18n.t('emojis.list.thread')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'thread',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🪡',
    description: `${i18n.t('emojis.list.sewing-needle')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'sewing_needle',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🧶',
    description: `${i18n.t('emojis.list.yarn')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'yarn',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🪢',
    description: `${i18n.t('emojis.list.knot')}`,
    category: `${i18n.t('emojis.categories.activities')}`,
    aliases: [
      'knot',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '👓',
    description: `${i18n.t('emojis.list.glasses')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'eyeglasses',
    ],
    tags: [
      'glasses',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕶️',
    description: `${i18n.t('emojis.list.sunglasses')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'dark_sunglasses',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🥽',
    description: `${i18n.t('emojis.list.goggles')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'goggles',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🥼',
    description: `${i18n.t('emojis.list.lab-coat')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'lab_coat',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🦺',
    description: `${i18n.t('emojis.list.safety-vest')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'safety_vest',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '👔',
    description: `${i18n.t('emojis.list.necktie')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'necktie',
    ],
    tags: [
      'shirt',
      'formal',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👕',
    description: `${i18n.t('emojis.list.t-shirt')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'shirt',
      'tshirt',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👖',
    description: `${i18n.t('emojis.list.jeans')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'jeans',
    ],
    tags: [
      'pants',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🧣',
    description: `${i18n.t('emojis.list.scarf')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'scarf',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧤',
    description: `${i18n.t('emojis.list.gloves')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'gloves',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧥',
    description: `${i18n.t('emojis.list.coat')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'coat',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧦',
    description: `${i18n.t('emojis.list.socks')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'socks',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '👗',
    description: `${i18n.t('emojis.list.dress')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'dress',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👘',
    description: `${i18n.t('emojis.list.kimono')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'kimono',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥻',
    description: `${i18n.t('emojis.list.sari')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'sari',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🩱',
    description: `${i18n.t('emojis.list.one-piece-swimsuit')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'one_piece_swimsuit',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🩲',
    description: `${i18n.t('emojis.list.briefs')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'swim_brief',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🩳',
    description: `${i18n.t('emojis.list.shorts')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'shorts',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '👙',
    description: `${i18n.t('emojis.list.bikini')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'bikini',
    ],
    tags: [
      'beach',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👚',
    description: `${i18n.t('emojis.list.woman-s-clothes')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'womans_clothes',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👛',
    description: `${i18n.t('emojis.list.purse')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'purse',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👜',
    description: `${i18n.t('emojis.list.handbag')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'handbag',
    ],
    tags: [
      'bag',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👝',
    description: `${i18n.t('emojis.list.clutch-bag')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'pouch',
    ],
    tags: [
      'bag',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🛍️',
    description: `${i18n.t('emojis.list.shopping-bags')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'shopping',
    ],
    tags: [
      'bags',
    ],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🎒',
    description: `${i18n.t('emojis.list.backpack')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'school_satchel',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🩴',
    description: `${i18n.t('emojis.list.thong-sandal')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'thong_sandal',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '👞',
    description: `${i18n.t('emojis.list.man-s-shoe')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'mans_shoe',
      'shoe',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👟',
    description: `${i18n.t('emojis.list.running-shoe')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'athletic_shoe',
    ],
    tags: [
      'sneaker',
      'sport',
      'running',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🥾',
    description: `${i18n.t('emojis.list.hiking-boot')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'hiking_boot',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🥿',
    description: `${i18n.t('emojis.list.flat-shoe')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'flat_shoe',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '👠',
    description: `${i18n.t('emojis.list.high-heeled-shoe')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'high_heel',
    ],
    tags: [
      'shoe',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👡',
    description: `${i18n.t('emojis.list.woman-s-sandal')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'sandal',
    ],
    tags: [
      'shoe',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🩰',
    description: `${i18n.t('emojis.list.ballet-shoes')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'ballet_shoes',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '👢',
    description: `${i18n.t('emojis.list.woman-s-boot')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'boot',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👑',
    description: `${i18n.t('emojis.list.crown')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'crown',
    ],
    tags: [
      'king',
      'queen',
      'royal',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '👒',
    description: `${i18n.t('emojis.list.woman-s-hat')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'womans_hat',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎩',
    description: `${i18n.t('emojis.list.top-hat')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'tophat',
    ],
    tags: [
      'hat',
      'classy',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎓',
    description: `${i18n.t('emojis.list.graduation-cap')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'mortar_board',
    ],
    tags: [
      'education',
      'college',
      'university',
      'graduation',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🧢',
    description: `${i18n.t('emojis.list.billed-cap')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'billed_cap',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🪖',
    description: `${i18n.t('emojis.list.military-helmet')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'military_helmet',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '⛑️',
    description: `${i18n.t('emojis.list.rescue-worker-s-helmet')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'rescue_worker_helmet',
    ],
    tags: [],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    emoji: '📿',
    description: `${i18n.t('emojis.list.prayer-beads')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'prayer_beads',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '💄',
    description: `${i18n.t('emojis.list.lipstick')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'lipstick',
    ],
    tags: [
      'makeup',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💍',
    description: `${i18n.t('emojis.list.ring')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'ring',
    ],
    tags: [
      'wedding',
      'marriage',
      'engaged',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💎',
    description: `${i18n.t('emojis.list.gem-stone')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'gem',
    ],
    tags: [
      'diamond',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔇',
    description: `${i18n.t('emojis.list.muted-speaker')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'mute',
    ],
    tags: [
      'sound',
      'volume',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔈',
    description: `${i18n.t('emojis.list.speaker-low-volume')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'speaker',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔉',
    description: `${i18n.t('emojis.list.speaker-medium-volume')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'sound',
    ],
    tags: [
      'volume',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔊',
    description: `${i18n.t('emojis.list.speaker-high-volume')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'loud_sound',
    ],
    tags: [
      'volume',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📢',
    description: `${i18n.t('emojis.list.loudspeaker')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'loudspeaker',
    ],
    tags: [
      'announcement',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📣',
    description: `${i18n.t('emojis.list.megaphone')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'mega',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📯',
    description: `${i18n.t('emojis.list.postal-horn')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'postal_horn',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔔',
    description: `${i18n.t('emojis.list.bell')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'bell',
    ],
    tags: [
      'sound',
      'notification',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔕',
    description: `${i18n.t('emojis.list.bell-with-slash')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'no_bell',
    ],
    tags: [
      'volume',
      'off',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎼',
    description: `${i18n.t('emojis.list.musical-score')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'musical_score',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎵',
    description: `${i18n.t('emojis.list.musical-note')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'musical_note',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎶',
    description: `${i18n.t('emojis.list.musical-notes')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'notes',
    ],
    tags: [
      'music',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎙️',
    description: `${i18n.t('emojis.list.studio-microphone')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'studio_microphone',
    ],
    tags: [
      'podcast',
    ],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🎚️',
    description: `${i18n.t('emojis.list.level-slider')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'level_slider',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🎛️',
    description: `${i18n.t('emojis.list.control-knobs')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'control_knobs',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🎤',
    description: `${i18n.t('emojis.list.microphone')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'microphone',
    ],
    tags: [
      'sing',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎧',
    description: `${i18n.t('emojis.list.headphone')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'headphones',
    ],
    tags: [
      'music',
      'earphones',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📻',
    description: `${i18n.t('emojis.list.radio')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'radio',
    ],
    tags: [
      'podcast',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎷',
    description: `${i18n.t('emojis.list.saxophone')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'saxophone',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪗',
    description: `${i18n.t('emojis.list.accordion')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'accordion',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🎸',
    description: `${i18n.t('emojis.list.guitar')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'guitar',
    ],
    tags: [
      'rock',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎹',
    description: `${i18n.t('emojis.list.musical-keyboard')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'musical_keyboard',
    ],
    tags: [
      'piano',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎺',
    description: `${i18n.t('emojis.list.trumpet')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'trumpet',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎻',
    description: `${i18n.t('emojis.list.violin')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'violin',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪕',
    description: `${i18n.t('emojis.list.banjo')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'banjo',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🥁',
    description: `${i18n.t('emojis.list.drum')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'drum',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '10.2',
  },
  {
    emoji: '🪘',
    description: `${i18n.t('emojis.list.long-drum')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'long_drum',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '📱',
    description: `${i18n.t('emojis.list.mobile-phone')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'iphone',
    ],
    tags: [
      'smartphone',
      'mobile',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📲',
    description: `${i18n.t('emojis.list.mobile-phone-with-arrow')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'calling',
    ],
    tags: [
      'call',
      'incoming',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '☎️',
    description: `${i18n.t('emojis.list.telephone')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'phone',
      'telephone',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '📞',
    description: `${i18n.t('emojis.list.telephone-receiver')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'telephone_receiver',
    ],
    tags: [
      'phone',
      'call',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📟',
    description: `${i18n.t('emojis.list.pager')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'pager',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📠',
    description: `${i18n.t('emojis.list.fax-machine')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'fax',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔋',
    description: `${i18n.t('emojis.list.battery')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'battery',
    ],
    tags: [
      'power',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪫',
    description: `${i18n.t('emojis.list.low-battery')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'low_battery',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🔌',
    description: `${i18n.t('emojis.list.electric-plug')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'electric_plug',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💻',
    description: `${i18n.t('emojis.list.laptop')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'computer',
    ],
    tags: [
      'desktop',
      'screen',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🖥️',
    description: `${i18n.t('emojis.list.desktop-computer')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'desktop_computer',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🖨️',
    description: `${i18n.t('emojis.list.printer')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'printer',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '⌨️',
    description: `${i18n.t('emojis.list.keyboard')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'keyboard',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    emoji: '🖱️',
    description: `${i18n.t('emojis.list.computer-mouse')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'computer_mouse',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🖲️',
    description: `${i18n.t('emojis.list.trackball')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'trackball',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '💽',
    description: `${i18n.t('emojis.list.computer-disk')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'minidisc',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💾',
    description: `${i18n.t('emojis.list.floppy-disk')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'floppy_disk',
    ],
    tags: [
      'save',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💿',
    description: `${i18n.t('emojis.list.optical-disk')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'cd',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📀',
    description: `${i18n.t('emojis.list.dvd')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'dvd',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🧮',
    description: `${i18n.t('emojis.list.abacus')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'abacus',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🎥',
    description: `${i18n.t('emojis.list.movie-camera')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'movie_camera',
    ],
    tags: [
      'film',
      'video',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎞️',
    description: `${i18n.t('emojis.list.film-frames')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'film_strip',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '📽️',
    description: `${i18n.t('emojis.list.film-projector')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'film_projector',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🎬',
    description: `${i18n.t('emojis.list.clapper-board')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'clapper',
    ],
    tags: [
      'film',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📺',
    description: `${i18n.t('emojis.list.television')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'tv',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📷',
    description: `${i18n.t('emojis.list.camera')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'camera',
    ],
    tags: [
      'photo',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📸',
    description: `${i18n.t('emojis.list.camera-with-flash')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'camera_flash',
    ],
    tags: [
      'photo',
    ],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '📹',
    description: `${i18n.t('emojis.list.video-camera')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'video_camera',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📼',
    description: `${i18n.t('emojis.list.videocassette')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'vhs',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔍',
    description: `${i18n.t('emojis.list.magnifying-glass-tilted-left')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'mag',
    ],
    tags: [
      'search',
      'zoom',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔎',
    description: `${i18n.t('emojis.list.magnifying-glass-tilted-right')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'mag_right',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🕯️',
    description: `${i18n.t('emojis.list.candle')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'candle',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '💡',
    description: `${i18n.t('emojis.list.light-bulb')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'bulb',
    ],
    tags: [
      'idea',
      'light',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔦',
    description: `${i18n.t('emojis.list.flashlight')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'flashlight',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏮',
    description: `${i18n.t('emojis.list.red-paper-lantern')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'izakaya_lantern',
      'lantern',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪔',
    description: `${i18n.t('emojis.list.diya-lamp')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'diya_lamp',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '📔',
    description: `${i18n.t('emojis.list.notebook-with-decorative-cover')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'notebook_with_decorative_cover',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📕',
    description: `${i18n.t('emojis.list.closed-book')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'closed_book',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📖',
    description: `${i18n.t('emojis.list.open-book')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'book',
      'open_book',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📗',
    description: `${i18n.t('emojis.list.green-book')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'green_book',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📘',
    description: `${i18n.t('emojis.list.blue-book')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'blue_book',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📙',
    description: `${i18n.t('emojis.list.orange-book')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'orange_book',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📚',
    description: `${i18n.t('emojis.list.books')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'books',
    ],
    tags: [
      'library',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📓',
    description: `${i18n.t('emojis.list.notebook')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'notebook',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📒',
    description: `${i18n.t('emojis.list.ledger')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'ledger',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📃',
    description: `${i18n.t('emojis.list.page-with-curl')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'page_with_curl',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📜',
    description: `${i18n.t('emojis.list.scroll')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'scroll',
    ],
    tags: [
      'document',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📄',
    description: `${i18n.t('emojis.list.page-facing-up')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'page_facing_up',
    ],
    tags: [
      'document',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📰',
    description: `${i18n.t('emojis.list.newspaper')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'newspaper',
    ],
    tags: [
      'press',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🗞️',
    description: `${i18n.t('emojis.list.rolled-up-newspaper')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'newspaper_roll',
    ],
    tags: [
      'press',
    ],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '📑',
    description: `${i18n.t('emojis.list.bookmark-tabs')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'bookmark_tabs',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔖',
    description: `${i18n.t('emojis.list.bookmark')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'bookmark',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏷️',
    description: `${i18n.t('emojis.list.label')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'label',
    ],
    tags: [
      'tag',
    ],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '💰',
    description: `${i18n.t('emojis.list.money-bag')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'moneybag',
    ],
    tags: [
      'dollar',
      'cream',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪙',
    description: `${i18n.t('emojis.list.coin')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'coin',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '💴',
    description: `${i18n.t('emojis.list.yen-banknote')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'yen',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💵',
    description: `${i18n.t('emojis.list.dollar-banknote')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'dollar',
    ],
    tags: [
      'money',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💶',
    description: `${i18n.t('emojis.list.euro-banknote')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'euro',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💷',
    description: `${i18n.t('emojis.list.pound-banknote')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'pound',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💸',
    description: `${i18n.t('emojis.list.money-with-wings')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'money_with_wings',
    ],
    tags: [
      'dollar',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💳',
    description: `${i18n.t('emojis.list.credit-card')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'credit_card',
    ],
    tags: [
      'subscription',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🧾',
    description: `${i18n.t('emojis.list.receipt')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'receipt',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '💹',
    description: `${i18n.t('emojis.list.chart-increasing-with-yen')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'chart',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '✉️',
    description: `${i18n.t('emojis.list.envelope')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'envelope',
    ],
    tags: [
      'letter',
      'email',
    ],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '📧',
    description: `${i18n.t('emojis.list.e-mail')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'email',
      'e-mail',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📨',
    description: `${i18n.t('emojis.list.incoming-envelope')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'incoming_envelope',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📩',
    description: `${i18n.t('emojis.list.envelope-with-arrow')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'envelope_with_arrow',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📤',
    description: `${i18n.t('emojis.list.outbox-tray')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'outbox_tray',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📥',
    description: `${i18n.t('emojis.list.inbox-tray')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'inbox_tray',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📦',
    description: `${i18n.t('emojis.list.package')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'package',
    ],
    tags: [
      'shipping',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📫',
    description: `${i18n.t('emojis.list.closed-mailbox-with-raised-flag')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'mailbox',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📪',
    description: `${i18n.t('emojis.list.closed-mailbox-with-lowered-flag')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'mailbox_closed',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📬',
    description: `${i18n.t('emojis.list.open-mailbox-with-raised-flag')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'mailbox_with_mail',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📭',
    description: `${i18n.t('emojis.list.open-mailbox-with-lowered-flag')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'mailbox_with_no_mail',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📮',
    description: `${i18n.t('emojis.list.postbox')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'postbox',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🗳️',
    description: `${i18n.t('emojis.list.ballot-box-with-ballot')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'ballot_box',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '✏️',
    description: `${i18n.t('emojis.list.pencil')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'pencil2',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '✒️',
    description: `${i18n.t('emojis.list.black-nib')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'black_nib',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '🖋️',
    description: `${i18n.t('emojis.list.fountain-pen')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'fountain_pen',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🖊️',
    description: `${i18n.t('emojis.list.pen')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'pen',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🖌️',
    description: `${i18n.t('emojis.list.paintbrush')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'paintbrush',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🖍️',
    description: `${i18n.t('emojis.list.crayon')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'crayon',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '📝',
    description: `${i18n.t('emojis.list.memo')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'memo',
      'pencil',
    ],
    tags: [
      'document',
      'note',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💼',
    description: `${i18n.t('emojis.list.briefcase')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'briefcase',
    ],
    tags: [
      'business',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📁',
    description: `${i18n.t('emojis.list.file-folder')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'file_folder',
    ],
    tags: [
      'directory',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📂',
    description: `${i18n.t('emojis.list.open-file-folder')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'open_file_folder',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🗂️',
    description: `${i18n.t('emojis.list.card-index-dividers')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'card_index_dividers',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '📅',
    description: `${i18n.t('emojis.list.calendar')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'date',
    ],
    tags: [
      'calendar',
      'schedule',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📆',
    description: `${i18n.t('emojis.list.tear-off-calendar')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'calendar',
    ],
    tags: [
      'schedule',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🗒️',
    description: `${i18n.t('emojis.list.spiral-notepad')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'spiral_notepad',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🗓️',
    description: `${i18n.t('emojis.list.spiral-calendar')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'spiral_calendar',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '📇',
    description: `${i18n.t('emojis.list.card-index')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'card_index',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📈',
    description: `${i18n.t('emojis.list.chart-increasing')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'chart_with_upwards_trend',
    ],
    tags: [
      'graph',
      'metrics',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📉',
    description: `${i18n.t('emojis.list.chart-decreasing')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'chart_with_downwards_trend',
    ],
    tags: [
      'graph',
      'metrics',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📊',
    description: `${i18n.t('emojis.list.bar-chart')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'bar_chart',
    ],
    tags: [
      'stats',
      'metrics',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📋',
    description: `${i18n.t('emojis.list.clipboard')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'clipboard',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📌',
    description: `${i18n.t('emojis.list.pushpin')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'pushpin',
    ],
    tags: [
      'location',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📍',
    description: `${i18n.t('emojis.list.round-pushpin')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'round_pushpin',
    ],
    tags: [
      'location',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📎',
    description: `${i18n.t('emojis.list.paperclip')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'paperclip',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🖇️',
    description: `${i18n.t('emojis.list.linked-paperclips')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'paperclips',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '📏',
    description: `${i18n.t('emojis.list.straight-ruler')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'straight_ruler',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📐',
    description: `${i18n.t('emojis.list.triangular-ruler')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'triangular_ruler',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '✂️',
    description: `${i18n.t('emojis.list.scissors')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'scissors',
    ],
    tags: [
      'cut',
    ],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '🗃️',
    description: `${i18n.t('emojis.list.card-file-box')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'card_file_box',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🗄️',
    description: `${i18n.t('emojis.list.file-cabinet')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'file_cabinet',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🗑️',
    description: `${i18n.t('emojis.list.wastebasket')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'wastebasket',
    ],
    tags: [
      'trash',
    ],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🔒',
    description: `${i18n.t('emojis.list.locked')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'lock',
    ],
    tags: [
      'security',
      'private',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔓',
    description: `${i18n.t('emojis.list.unlocked')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'unlock',
    ],
    tags: [
      'security',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔏',
    description: `${i18n.t('emojis.list.locked-with-pen')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'lock_with_ink_pen',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔐',
    description: `${i18n.t('emojis.list.locked-with-key')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'closed_lock_with_key',
    ],
    tags: [
      'security',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔑',
    description: `${i18n.t('emojis.list.key')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'key',
    ],
    tags: [
      'lock',
      'password',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🗝️',
    description: `${i18n.t('emojis.list.old-key')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'old_key',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🔨',
    description: `${i18n.t('emojis.list.hammer')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'hammer',
    ],
    tags: [
      'tool',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪓',
    description: `${i18n.t('emojis.list.axe')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'axe',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '⛏️',
    description: `${i18n.t('emojis.list.pick')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'pick',
    ],
    tags: [],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    emoji: '⚒️',
    description: `${i18n.t('emojis.list.hammer-and-pick')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'hammer_and_pick',
    ],
    tags: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    emoji: '🛠️',
    description: `${i18n.t('emojis.list.hammer-and-wrench')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'hammer_and_wrench',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🗡️',
    description: `${i18n.t('emojis.list.dagger')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'dagger',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '⚔️',
    description: `${i18n.t('emojis.list.crossed-swords')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'crossed_swords',
    ],
    tags: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    emoji: '🔫',
    description: `${i18n.t('emojis.list.water-pistol')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'gun',
    ],
    tags: [
      'shoot',
      'weapon',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪃',
    description: `${i18n.t('emojis.list.boomerang')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'boomerang',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🏹',
    description: `${i18n.t('emojis.list.bow-and-arrow')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'bow_and_arrow',
    ],
    tags: [
      'archery',
    ],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🛡️',
    description: `${i18n.t('emojis.list.shield')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'shield',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🪚',
    description: `${i18n.t('emojis.list.carpentry-saw')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'carpentry_saw',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🔧',
    description: `${i18n.t('emojis.list.wrench')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'wrench',
    ],
    tags: [
      'tool',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪛',
    description: `${i18n.t('emojis.list.screwdriver')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'screwdriver',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🔩',
    description: `${i18n.t('emojis.list.nut-and-bolt')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'nut_and_bolt',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '⚙️',
    description: `${i18n.t('emojis.list.gear')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'gear',
    ],
    tags: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    emoji: '🗜️',
    description: `${i18n.t('emojis.list.clamp')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'clamp',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '⚖️',
    description: `${i18n.t('emojis.list.balance-scale')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'balance_scale',
    ],
    tags: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    emoji: '🦯',
    description: `${i18n.t('emojis.list.white-cane')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'probing_cane',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🔗',
    description: `${i18n.t('emojis.list.link')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'link',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '⛓️',
    description: `${i18n.t('emojis.list.chains')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'chains',
    ],
    tags: [],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    emoji: '🪝',
    description: `${i18n.t('emojis.list.hook')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'hook',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🧰',
    description: `${i18n.t('emojis.list.toolbox')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'toolbox',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧲',
    description: `${i18n.t('emojis.list.magnet')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'magnet',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🪜',
    description: `${i18n.t('emojis.list.ladder')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'ladder',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '⚗️',
    description: `${i18n.t('emojis.list.alembic')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'alembic',
    ],
    tags: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    emoji: '🧪',
    description: `${i18n.t('emojis.list.test-tube')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'test_tube',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧫',
    description: `${i18n.t('emojis.list.petri-dish')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'petri_dish',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧬',
    description: `${i18n.t('emojis.list.dna')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'dna',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🔬',
    description: `${i18n.t('emojis.list.microscope')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'microscope',
    ],
    tags: [
      'science',
      'laboratory',
      'investigate',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔭',
    description: `${i18n.t('emojis.list.telescope')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'telescope',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📡',
    description: `${i18n.t('emojis.list.satellite-antenna')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'satellite',
    ],
    tags: [
      'signal',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💉',
    description: `${i18n.t('emojis.list.syringe')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'syringe',
    ],
    tags: [
      'health',
      'hospital',
      'needle',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🩸',
    description: `${i18n.t('emojis.list.drop-of-blood')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'drop_of_blood',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '💊',
    description: `${i18n.t('emojis.list.pill')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'pill',
    ],
    tags: [
      'health',
      'medicine',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🩹',
    description: `${i18n.t('emojis.list.adhesive-bandage')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'adhesive_bandage',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🩼',
    description: `${i18n.t('emojis.list.crutch')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'crutch',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🩺',
    description: `${i18n.t('emojis.list.stethoscope')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'stethoscope',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🩻',
    description: `${i18n.t('emojis.list.x-ray')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'x_ray',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🚪',
    description: `${i18n.t('emojis.list.door')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'door',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🛗',
    description: `${i18n.t('emojis.list.elevator')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'elevator',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🪞',
    description: `${i18n.t('emojis.list.mirror')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'mirror',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🪟',
    description: `${i18n.t('emojis.list.window')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'window',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🛏️',
    description: `${i18n.t('emojis.list.bed')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'bed',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🛋️',
    description: `${i18n.t('emojis.list.couch-and-lamp')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'couch_and_lamp',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🪑',
    description: `${i18n.t('emojis.list.chair')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'chair',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🚽',
    description: `${i18n.t('emojis.list.toilet')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'toilet',
    ],
    tags: [
      'wc',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪠',
    description: `${i18n.t('emojis.list.plunger')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'plunger',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🚿',
    description: `${i18n.t('emojis.list.shower')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'shower',
    ],
    tags: [
      'bath',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🛁',
    description: `${i18n.t('emojis.list.bathtub')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'bathtub',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪤',
    description: `${i18n.t('emojis.list.mouse-trap')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'mouse_trap',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🪒',
    description: `${i18n.t('emojis.list.razor')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'razor',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🧴',
    description: `${i18n.t('emojis.list.lotion-bottle')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'lotion_bottle',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧷',
    description: `${i18n.t('emojis.list.safety-pin')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'safety_pin',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧹',
    description: `${i18n.t('emojis.list.broom')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'broom',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧺',
    description: `${i18n.t('emojis.list.basket')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'basket',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧻',
    description: `${i18n.t('emojis.list.roll-of-paper')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'roll_of_paper',
    ],
    tags: [
      'toilet',
    ],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🪣',
    description: `${i18n.t('emojis.list.bucket')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'bucket',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🧼',
    description: `${i18n.t('emojis.list.soap')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'soap',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🫧',
    description: `${i18n.t('emojis.list.bubbles')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'bubbles',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🪥',
    description: `${i18n.t('emojis.list.toothbrush')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'toothbrush',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🧽',
    description: `${i18n.t('emojis.list.sponge')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'sponge',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🧯',
    description: `${i18n.t('emojis.list.fire-extinguisher')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'fire_extinguisher',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🛒',
    description: `${i18n.t('emojis.list.shopping-cart')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'shopping_cart',
    ],
    tags: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    emoji: '🚬',
    description: `${i18n.t('emojis.list.cigarette')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'smoking',
    ],
    tags: [
      'cigarette',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '⚰️',
    description: `${i18n.t('emojis.list.coffin')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'coffin',
    ],
    tags: [
      'funeral',
    ],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    emoji: '🪦',
    description: `${i18n.t('emojis.list.headstone')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'headstone',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '⚱️',
    description: `${i18n.t('emojis.list.funeral-urn')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'funeral_urn',
    ],
    tags: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    emoji: '🗿',
    description: `${i18n.t('emojis.list.moai')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'moyai',
    ],
    tags: [
      'stone',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🪧',
    description: `${i18n.t('emojis.list.placard')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'placard',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🪪',
    description: `${i18n.t('emojis.list.identification-card')}`,
    category: `${i18n.t('emojis.categories.objects')}`,
    aliases: [
      'identification_card',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '🏧',
    description: `${i18n.t('emojis.list.atm-sign')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'atm',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚮',
    description: `${i18n.t('emojis.list.litter-in-bin-sign')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'put_litter_in_its_place',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚰',
    description: `${i18n.t('emojis.list.potable-water')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'potable_water',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '♿',
    description: `${i18n.t('emojis.list.wheelchair-symbol')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'wheelchair',
    ],
    tags: [
      'accessibility',
    ],
    unicode_version: '4.1',
    ios_version: '6.0',
  },
  {
    emoji: '🚹',
    description: `${i18n.t('emojis.list.men-s-room')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'mens',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚺',
    description: `${i18n.t('emojis.list.women-s-room')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'womens',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚻',
    description: `${i18n.t('emojis.list.restroom')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'restroom',
    ],
    tags: [
      'toilet',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚼',
    description: `${i18n.t('emojis.list.baby-symbol')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'baby_symbol',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚾',
    description: `${i18n.t('emojis.list.water-closet')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'wc',
    ],
    tags: [
      'toilet',
      'restroom',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🛂',
    description: `${i18n.t('emojis.list.passport-control')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'passport_control',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🛃',
    description: `${i18n.t('emojis.list.customs')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'customs',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🛄',
    description: `${i18n.t('emojis.list.baggage-claim')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'baggage_claim',
    ],
    tags: [
      'airport',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🛅',
    description: `${i18n.t('emojis.list.left-luggage')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'left_luggage',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '⚠️',
    description: `${i18n.t('emojis.list.warning')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'warning',
    ],
    tags: [
      'wip',
    ],
    unicode_version: '4.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚸',
    description: `${i18n.t('emojis.list.children-crossing')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'children_crossing',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '⛔',
    description: `${i18n.t('emojis.list.no-entry')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'no_entry',
    ],
    tags: [
      'limit',
    ],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    emoji: '🚫',
    description: `${i18n.t('emojis.list.prohibited')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'no_entry_sign',
    ],
    tags: [
      'block',
      'forbidden',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚳',
    description: `${i18n.t('emojis.list.no-bicycles')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'no_bicycles',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚭',
    description: `${i18n.t('emojis.list.no-smoking')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'no_smoking',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚯',
    description: `${i18n.t('emojis.list.no-littering')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'do_not_litter',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚱',
    description: `${i18n.t('emojis.list.non-potable-water')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'non-potable_water',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚷',
    description: `${i18n.t('emojis.list.no-pedestrians')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'no_pedestrians',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📵',
    description: `${i18n.t('emojis.list.no-mobile-phones')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'no_mobile_phones',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔞',
    description: `${i18n.t('emojis.list.no-one-under-eighteen')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'underage',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '☢️',
    description: `${i18n.t('emojis.list.radioactive')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'radioactive',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    emoji: '☣️',
    description: `${i18n.t('emojis.list.biohazard')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'biohazard',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    emoji: '⬆️',
    description: `${i18n.t('emojis.list.up-arrow')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_up',
    ],
    tags: [],
    unicode_version: '4.0',
    ios_version: '6.0',
  },
  {
    emoji: '↗️',
    description: `${i18n.t('emojis.list.up-right-arrow')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_upper_right',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '➡️',
    description: `${i18n.t('emojis.list.right-arrow')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_right',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '↘️',
    description: `${i18n.t('emojis.list.down-right-arrow')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_lower_right',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '⬇️',
    description: `${i18n.t('emojis.list.down-arrow')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_down',
    ],
    tags: [],
    unicode_version: '4.0',
    ios_version: '6.0',
  },
  {
    emoji: '↙️',
    description: `${i18n.t('emojis.list.down-left-arrow')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_lower_left',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '⬅️',
    description: `${i18n.t('emojis.list.left-arrow')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_left',
    ],
    tags: [],
    unicode_version: '4.0',
    ios_version: '6.0',
  },
  {
    emoji: '↖️',
    description: `${i18n.t('emojis.list.up-left-arrow')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_upper_left',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '↕️',
    description: `${i18n.t('emojis.list.up-down-arrow')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_up_down',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '↔️',
    description: `${i18n.t('emojis.list.left-right-arrow')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'left_right_arrow',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '↩️',
    description: `${i18n.t('emojis.list.right-arrow-curving-left')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'leftwards_arrow_with_hook',
    ],
    tags: [
      'return',
    ],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '↪️',
    description: `${i18n.t('emojis.list.left-arrow-curving-right')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_right_hook',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '⤴️',
    description: `${i18n.t('emojis.list.right-arrow-curving-up')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_heading_up',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '⤵️',
    description: `${i18n.t('emojis.list.right-arrow-curving-down')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_heading_down',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '🔃',
    description: `${i18n.t('emojis.list.clockwise-vertical-arrows')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrows_clockwise',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔄',
    description: `${i18n.t('emojis.list.counterclockwise-arrows-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrows_counterclockwise',
    ],
    tags: [
      'sync',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔙',
    description: `${i18n.t('emojis.list.back-arrow')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'back',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔚',
    description: `${i18n.t('emojis.list.end-arrow')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'end',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔛',
    description: `${i18n.t('emojis.list.on-arrow')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'on',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔜',
    description: `${i18n.t('emojis.list.soon-arrow')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'soon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔝',
    description: `${i18n.t('emojis.list.top-arrow')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'top',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🛐',
    description: `${i18n.t('emojis.list.place-of-worship')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'place_of_worship',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '⚛️',
    description: `${i18n.t('emojis.list.atom-symbol')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'atom_symbol',
    ],
    tags: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    emoji: '🕉️',
    description: `${i18n.t('emojis.list.om')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'om',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '✡️',
    description: `${i18n.t('emojis.list.star-of-david')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'star_of_david',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    emoji: '☸️',
    description: `${i18n.t('emojis.list.wheel-of-dharma')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'wheel_of_dharma',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    emoji: '☯️',
    description: `${i18n.t('emojis.list.yin-yang')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'yin_yang',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    emoji: '✝️',
    description: `${i18n.t('emojis.list.latin-cross')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'latin_cross',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    emoji: '☦️',
    description: `${i18n.t('emojis.list.orthodox-cross')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'orthodox_cross',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    emoji: '☪️',
    description: `${i18n.t('emojis.list.star-and-crescent')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'star_and_crescent',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    emoji: '☮️',
    description: `${i18n.t('emojis.list.peace-symbol')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'peace_symbol',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    emoji: '🕎',
    description: `${i18n.t('emojis.list.menorah')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'menorah',
    ],
    tags: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🔯',
    description: `${i18n.t('emojis.list.dotted-six-pointed-star')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'six_pointed_star',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '♈',
    description: `${i18n.t('emojis.list.aries')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'aries',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '♉',
    description: `${i18n.t('emojis.list.taurus')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'taurus',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '♊',
    description: `${i18n.t('emojis.list.gemini')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'gemini',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '♋',
    description: `${i18n.t('emojis.list.cancer')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'cancer',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '♌',
    description: `${i18n.t('emojis.list.leo')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'leo',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '♍',
    description: `${i18n.t('emojis.list.virgo')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'virgo',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '♎',
    description: `${i18n.t('emojis.list.libra')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'libra',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '♏',
    description: `${i18n.t('emojis.list.scorpio')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'scorpius',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '♐',
    description: `${i18n.t('emojis.list.sagittarius')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'sagittarius',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '♑',
    description: `${i18n.t('emojis.list.capricorn')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'capricorn',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '♒',
    description: `${i18n.t('emojis.list.aquarius')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'aquarius',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '♓',
    description: `${i18n.t('emojis.list.pisces')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'pisces',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '⛎',
    description: `${i18n.t('emojis.list.ophiuchus')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'ophiuchus',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔀',
    description: `${i18n.t('emojis.list.shuffle-tracks-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'twisted_rightwards_arrows',
    ],
    tags: [
      'shuffle',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔁',
    description: `${i18n.t('emojis.list.repeat-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'repeat',
    ],
    tags: [
      'loop',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔂',
    description: `${i18n.t('emojis.list.repeat-single-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'repeat_one',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '▶️',
    description: `${i18n.t('emojis.list.play-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_forward',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '⏩',
    description: `${i18n.t('emojis.list.fast-forward-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'fast_forward',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '⏭️',
    description: `${i18n.t('emojis.list.next-track-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'next_track_button',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.1',
  },
  {
    emoji: '⏯️',
    description: `${i18n.t('emojis.list.play-or-pause-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'play_or_pause_button',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.1',
  },
  {
    emoji: '◀️',
    description: `${i18n.t('emojis.list.reverse-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_backward',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '⏪',
    description: `${i18n.t('emojis.list.fast-reverse-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'rewind',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '⏮️',
    description: `${i18n.t('emojis.list.last-track-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'previous_track_button',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.1',
  },
  {
    emoji: '🔼',
    description: `${i18n.t('emojis.list.upwards-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_up_small',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '⏫',
    description: `${i18n.t('emojis.list.fast-up-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_double_up',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔽',
    description: `${i18n.t('emojis.list.downwards-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_down_small',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '⏬',
    description: `${i18n.t('emojis.list.fast-down-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'arrow_double_down',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '⏸️',
    description: `${i18n.t('emojis.list.pause-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'pause_button',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '⏹️',
    description: `${i18n.t('emojis.list.stop-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'stop_button',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '⏺️',
    description: `${i18n.t('emojis.list.record-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'record_button',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '⏏️',
    description: `${i18n.t('emojis.list.eject-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'eject_button',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🎦',
    description: `${i18n.t('emojis.list.cinema')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'cinema',
    ],
    tags: [
      'film',
      'movie',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔅',
    description: `${i18n.t('emojis.list.dim-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'low_brightness',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔆',
    description: `${i18n.t('emojis.list.bright-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'high_brightness',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📶',
    description: `${i18n.t('emojis.list.antenna-bars')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'signal_strength',
    ],
    tags: [
      'wifi',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📳',
    description: `${i18n.t('emojis.list.vibration-mode')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'vibration_mode',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📴',
    description: `${i18n.t('emojis.list.mobile-phone-off')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'mobile_phone_off',
    ],
    tags: [
      'mute',
      'off',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '♀️',
    description: `${i18n.t('emojis.list.female-sign')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'female_sign',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '♂️',
    description: `${i18n.t('emojis.list.male-sign')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'male_sign',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '⚧️',
    description: `${i18n.t('emojis.list.transgender-symbol')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'transgender_symbol',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '✖️',
    description: `${i18n.t('emojis.list.multiply')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'heavy_multiplication_x',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '➕',
    description: `${i18n.t('emojis.list.plus')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'heavy_plus_sign',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '➖',
    description: `${i18n.t('emojis.list.minus')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'heavy_minus_sign',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '➗',
    description: `${i18n.t('emojis.list.divide')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'heavy_division_sign',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🟰',
    description: `${i18n.t('emojis.list.heavy-equals-sign')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'heavy_equals_sign',
    ],
    tags: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    emoji: '♾️',
    description: `${i18n.t('emojis.list.infinity')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'infinity',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '‼️',
    description: `${i18n.t('emojis.list.double-exclamation-mark')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'bangbang',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '⁉️',
    description: `${i18n.t('emojis.list.exclamation-question-mark')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'interrobang',
    ],
    tags: [],
    unicode_version: '3.0',
    ios_version: '6.0',
  },
  {
    emoji: '❓',
    description: `${i18n.t('emojis.list.red-question-mark')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'question',
    ],
    tags: [
      'confused',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '❔',
    description: `${i18n.t('emojis.list.white-question-mark')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'grey_question',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '❕',
    description: `${i18n.t('emojis.list.white-exclamation-mark')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'grey_exclamation',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '❗',
    description: `${i18n.t('emojis.list.red-exclamation-mark')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'exclamation',
      'heavy_exclamation_mark',
    ],
    tags: [
      'bang',
    ],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    emoji: '〰️',
    description: `${i18n.t('emojis.list.wavy-dash')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'wavy_dash',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '💱',
    description: `${i18n.t('emojis.list.currency-exchange')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'currency_exchange',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💲',
    description: `${i18n.t('emojis.list.heavy-dollar-sign')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'heavy_dollar_sign',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '⚕️',
    description: `${i18n.t('emojis.list.medical-symbol')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'medical_symbol',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '♻️',
    description: `${i18n.t('emojis.list.recycling-symbol')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'recycle',
    ],
    tags: [
      'environment',
      'green',
    ],
    unicode_version: '3.2',
    ios_version: '6.0',
  },
  {
    emoji: '⚜️',
    description: `${i18n.t('emojis.list.fleur-de-lis')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'fleur_de_lis',
    ],
    tags: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    emoji: '🔱',
    description: `${i18n.t('emojis.list.trident-emblem')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'trident',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '📛',
    description: `${i18n.t('emojis.list.name-badge')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'name_badge',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔰',
    description: `${i18n.t('emojis.list.japanese-symbol-for-beginner')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'beginner',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '⭕',
    description: `${i18n.t('emojis.list.hollow-red-circle')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'o',
    ],
    tags: [],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    emoji: '✅',
    description: `${i18n.t('emojis.list.check-mark-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'white_check_mark',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '☑️',
    description: `${i18n.t('emojis.list.check-box-with-check')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'ballot_box_with_check',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '✔️',
    description: `${i18n.t('emojis.list.check-mark')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'heavy_check_mark',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '❌',
    description: `${i18n.t('emojis.list.cross-mark')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'x',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '❎',
    description: `${i18n.t('emojis.list.cross-mark-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'negative_squared_cross_mark',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '➰',
    description: `${i18n.t('emojis.list.curly-loop')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'curly_loop',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '➿',
    description: `${i18n.t('emojis.list.double-curly-loop')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'loop',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '〽️',
    description: `${i18n.t('emojis.list.part-alternation-mark')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'part_alternation_mark',
    ],
    tags: [],
    unicode_version: '3.2',
    ios_version: '6.0',
  },
  {
    emoji: '✳️',
    description: `${i18n.t('emojis.list.eight-spoked-asterisk')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'eight_spoked_asterisk',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '✴️',
    description: `${i18n.t('emojis.list.eight-pointed-star')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'eight_pointed_black_star',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '❇️',
    description: `${i18n.t('emojis.list.sparkle')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'sparkle',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '©️',
    description: `${i18n.t('emojis.list.copyright')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'copyright',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '®️',
    description: `${i18n.t('emojis.list.registered')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'registered',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '™️',
    description: `${i18n.t('emojis.list.trade-mark')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'tm',
    ],
    tags: [
      'trademark',
    ],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '#️⃣',
    description: `${i18n.t('emojis.list.keycap')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'hash',
    ],
    tags: [
      'number',
    ],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '*️⃣',
    description: `${i18n.t('emojis.list.keycap')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'asterisk',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    emoji: '0️⃣',
    description: `${i18n.t('emojis.list.keycap-0')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'zero',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '1️⃣',
    description: `${i18n.t('emojis.list.keycap-1')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'one',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '2️⃣',
    description: `${i18n.t('emojis.list.keycap-2')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'two',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '3️⃣',
    description: `${i18n.t('emojis.list.keycap-3')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'three',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '4️⃣',
    description: `${i18n.t('emojis.list.keycap-4')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'four',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '5️⃣',
    description: `${i18n.t('emojis.list.keycap-5')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'five',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '6️⃣',
    description: `${i18n.t('emojis.list.keycap-6')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'six',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '7️⃣',
    description: `${i18n.t('emojis.list.keycap-7')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'seven',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '8️⃣',
    description: `${i18n.t('emojis.list.keycap-8')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'eight',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '9️⃣',
    description: `${i18n.t('emojis.list.keycap-9')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'nine',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '🔟',
    description: `${i18n.t('emojis.list.keycap-10')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'keycap_ten',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔠',
    description: `${i18n.t('emojis.list.input-latin-uppercase')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'capital_abcd',
    ],
    tags: [
      'letters',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔡',
    description: `${i18n.t('emojis.list.input-latin-lowercase')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'abcd',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔢',
    description: `${i18n.t('emojis.list.input-numbers')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      '1234',
    ],
    tags: [
      'numbers',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔣',
    description: `${i18n.t('emojis.list.input-symbols')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'symbols',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔤',
    description: `${i18n.t('emojis.list.input-latin-letters')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'abc',
    ],
    tags: [
      'alphabet',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🅰️',
    description: `${i18n.t('emojis.list.a-button-blood-type')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'a',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🆎',
    description: `${i18n.t('emojis.list.ab-button-blood-type')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'ab',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🅱️',
    description: `${i18n.t('emojis.list.b-button-blood-type')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'b',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🆑',
    description: `${i18n.t('emojis.list.cl-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'cl',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🆒',
    description: `${i18n.t('emojis.list.cool-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'cool',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🆓',
    description: `${i18n.t('emojis.list.free-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'free',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: 'ℹ️',
    description: `${i18n.t('emojis.list.information')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'information_source',
    ],
    tags: [],
    unicode_version: '3.0',
    ios_version: '6.0',
  },
  {
    emoji: '🆔',
    description: `${i18n.t('emojis.list.id-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'id',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: 'Ⓜ️',
    description: `${i18n.t('emojis.list.circled-m')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'm',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '🆕',
    description: `${i18n.t('emojis.list.new-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'new',
    ],
    tags: [
      'fresh',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🆖',
    description: `${i18n.t('emojis.list.ng-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'ng',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🅾️',
    description: `${i18n.t('emojis.list.o-button-blood-type')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'o2',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🆗',
    description: `${i18n.t('emojis.list.ok-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'ok',
    ],
    tags: [
      'yes',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🅿️',
    description: `${i18n.t('emojis.list.p-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'parking',
    ],
    tags: [],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    emoji: '🆘',
    description: `${i18n.t('emojis.list.sos-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'sos',
    ],
    tags: [
      'help',
      'emergency',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🆙',
    description: `${i18n.t('emojis.list.up-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'up',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🆚',
    description: `${i18n.t('emojis.list.vs-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'vs',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🈁',
    description: `${i18n.t('emojis.list.japanese-here-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'koko',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🈂️',
    description: `${i18n.t('emojis.list.japanese-service-charge-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'sa',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🈷️',
    description: `${i18n.t('emojis.list.japanese-monthly-amount-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'u6708',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🈶',
    description: `${i18n.t('emojis.list.japanese-not-free-of-charge-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'u6709',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🈯',
    description: `${i18n.t('emojis.list.japanese-reserved-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'u6307',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '🉐',
    description: `${i18n.t('emojis.list.japanese-bargain-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'ideograph_advantage',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🈹',
    description: `${i18n.t('emojis.list.japanese-discount-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'u5272',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🈚',
    description: `${i18n.t('emojis.list.japanese-free-of-charge-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'u7121',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '🈲',
    description: `${i18n.t('emojis.list.japanese-prohibited-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'u7981',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🉑',
    description: `${i18n.t('emojis.list.japanese-acceptable-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'accept',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🈸',
    description: `${i18n.t('emojis.list.japanese-application-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'u7533',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🈴',
    description: `${i18n.t('emojis.list.japanese-passing-grade-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'u5408',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🈳',
    description: `${i18n.t('emojis.list.japanese-vacancy-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'u7a7a',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '㊗️',
    description: `${i18n.t('emojis.list.japanese-congratulations-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'congratulations',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '㊙️',
    description: `${i18n.t('emojis.list.japanese-secret-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'secret',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '🈺',
    description: `${i18n.t('emojis.list.japanese-open-for-business-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'u55b6',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🈵',
    description: `${i18n.t('emojis.list.japanese-no-vacancy-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'u6e80',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔴',
    description: `${i18n.t('emojis.list.red-circle')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'red_circle',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🟠',
    description: `${i18n.t('emojis.list.orange-circle')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'orange_circle',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🟡',
    description: `${i18n.t('emojis.list.yellow-circle')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'yellow_circle',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🟢',
    description: `${i18n.t('emojis.list.green-circle')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'green_circle',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🔵',
    description: `${i18n.t('emojis.list.blue-circle')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'large_blue_circle',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🟣',
    description: `${i18n.t('emojis.list.purple-circle')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'purple_circle',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🟤',
    description: `${i18n.t('emojis.list.brown-circle')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'brown_circle',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '⚫',
    description: `${i18n.t('emojis.list.black-circle')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'black_circle',
    ],
    tags: [],
    unicode_version: '4.1',
    ios_version: '6.0',
  },
  {
    emoji: '⚪',
    description: `${i18n.t('emojis.list.white-circle')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'white_circle',
    ],
    tags: [],
    unicode_version: '4.1',
    ios_version: '6.0',
  },
  {
    emoji: '🟥',
    description: `${i18n.t('emojis.list.red-square')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'red_square',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🟧',
    description: `${i18n.t('emojis.list.orange-square')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'orange_square',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🟨',
    description: `${i18n.t('emojis.list.yellow-square')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'yellow_square',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🟩',
    description: `${i18n.t('emojis.list.green-square')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'green_square',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🟦',
    description: `${i18n.t('emojis.list.blue-square')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'blue_square',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🟪',
    description: `${i18n.t('emojis.list.purple-square')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'purple_square',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '🟫',
    description: `${i18n.t('emojis.list.brown-square')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'brown_square',
    ],
    tags: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    emoji: '⬛',
    description: `${i18n.t('emojis.list.black-large-square')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'black_large_square',
    ],
    tags: [],
    unicode_version: '5.1',
    ios_version: '6.0',
  },
  {
    emoji: '⬜',
    description: `${i18n.t('emojis.list.white-large-square')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'white_large_square',
    ],
    tags: [],
    unicode_version: '5.1',
    ios_version: '6.0',
  },
  {
    emoji: '◼️',
    description: `${i18n.t('emojis.list.black-medium-square')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'black_medium_square',
    ],
    tags: [],
    unicode_version: '3.2',
    ios_version: '6.0',
  },
  {
    emoji: '◻️',
    description: `${i18n.t('emojis.list.white-medium-square')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'white_medium_square',
    ],
    tags: [],
    unicode_version: '3.2',
    ios_version: '6.0',
  },
  {
    emoji: '◾',
    description: `${i18n.t('emojis.list.black-medium-small-square')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'black_medium_small_square',
    ],
    tags: [],
    unicode_version: '3.2',
    ios_version: '6.0',
  },
  {
    emoji: '◽',
    description: `${i18n.t('emojis.list.white-medium-small-square')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'white_medium_small_square',
    ],
    tags: [],
    unicode_version: '3.2',
    ios_version: '6.0',
  },
  {
    emoji: '▪️',
    description: `${i18n.t('emojis.list.black-small-square')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'black_small_square',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '▫️',
    description: `${i18n.t('emojis.list.white-small-square')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'white_small_square',
    ],
    tags: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    emoji: '🔶',
    description: `${i18n.t('emojis.list.large-orange-diamond')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'large_orange_diamond',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔷',
    description: `${i18n.t('emojis.list.large-blue-diamond')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'large_blue_diamond',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔸',
    description: `${i18n.t('emojis.list.small-orange-diamond')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'small_orange_diamond',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔹',
    description: `${i18n.t('emojis.list.small-blue-diamond')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'small_blue_diamond',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔺',
    description: `${i18n.t('emojis.list.red-triangle-pointed-up')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'small_red_triangle',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔻',
    description: `${i18n.t('emojis.list.red-triangle-pointed-down')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'small_red_triangle_down',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '💠',
    description: `${i18n.t('emojis.list.diamond-with-a-dot')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'diamond_shape_with_a_dot_inside',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔘',
    description: `${i18n.t('emojis.list.radio-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'radio_button',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔳',
    description: `${i18n.t('emojis.list.white-square-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'white_square_button',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🔲',
    description: `${i18n.t('emojis.list.black-square-button')}`,
    category: `${i18n.t('emojis.categories.symbols')}`,
    aliases: [
      'black_square_button',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏁',
    description: `${i18n.t('emojis.list.chequered-flag')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'checkered_flag',
    ],
    tags: [
      'milestone',
      'finish',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🚩',
    description: `${i18n.t('emojis.list.triangular-flag')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'triangular_flag_on_post',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🎌',
    description: `${i18n.t('emojis.list.crossed-flags')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'crossed_flags',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🏴',
    description: `${i18n.t('emojis.list.black-flag')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'black_flag',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🏳️',
    description: `${i18n.t('emojis.list.white-flag')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'white_flag',
    ],
    tags: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    emoji: '🏳️‍🌈',
    description: `${i18n.t('emojis.list.rainbow-flag')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'rainbow_flag',
    ],
    tags: [
      'pride',
    ],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    emoji: '🏳️‍⚧️',
    description: `${i18n.t('emojis.list.transgender-flag')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'transgender_flag',
    ],
    tags: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    emoji: '🏴‍☠️',
    description: `${i18n.t('emojis.list.pirate-flag')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'pirate_flag',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🇦🇨',
    description: `${i18n.t('emojis.list.flag-ascension-island')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'ascension_island',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🇦🇩',
    description: `${i18n.t('emojis.list.flag-andorra')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'andorra',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇦🇪',
    description: `${i18n.t('emojis.list.flag-united-arab-emirates')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'united_arab_emirates',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇦🇫',
    description: `${i18n.t('emojis.list.flag-afghanistan')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'afghanistan',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇦🇬',
    description: `${i18n.t('emojis.list.flag-antigua-barbuda')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'antigua_barbuda',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇦🇮',
    description: `${i18n.t('emojis.list.flag-anguilla')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'anguilla',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇦🇱',
    description: `${i18n.t('emojis.list.flag-albania')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'albania',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇦🇲',
    description: `${i18n.t('emojis.list.flag-armenia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'armenia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇦🇴',
    description: `${i18n.t('emojis.list.flag-angola')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'angola',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇦🇶',
    description: `${i18n.t('emojis.list.flag-antarctica')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'antarctica',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇦🇷',
    description: `${i18n.t('emojis.list.flag-argentina')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'argentina',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇦🇸',
    description: `${i18n.t('emojis.list.flag-american-samoa')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'american_samoa',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇦🇹',
    description: `${i18n.t('emojis.list.flag-austria')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'austria',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇦🇺',
    description: `${i18n.t('emojis.list.flag-australia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'australia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇦🇼',
    description: `${i18n.t('emojis.list.flag-aruba')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'aruba',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇦🇽',
    description: `${i18n.t('emojis.list.flag-aland-islands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'aland_islands',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇦🇿',
    description: `${i18n.t('emojis.list.flag-azerbaijan')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'azerbaijan',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇦',
    description: `${i18n.t('emojis.list.flag-bosnia-herzegovina')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'bosnia_herzegovina',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇧',
    description: `${i18n.t('emojis.list.flag-barbados')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'barbados',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇩',
    description: `${i18n.t('emojis.list.flag-bangladesh')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'bangladesh',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇪',
    description: `${i18n.t('emojis.list.flag-belgium')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'belgium',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇫',
    description: `${i18n.t('emojis.list.flag-burkina-faso')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'burkina_faso',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇬',
    description: `${i18n.t('emojis.list.flag-bulgaria')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'bulgaria',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇭',
    description: `${i18n.t('emojis.list.flag-bahrain')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'bahrain',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇮',
    description: `${i18n.t('emojis.list.flag-burundi')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'burundi',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇯',
    description: `${i18n.t('emojis.list.flag-benin')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'benin',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇱',
    description: `${i18n.t('emojis.list.flag-st-barthelemy')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'st_barthelemy',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇧🇲',
    description: `${i18n.t('emojis.list.flag-bermuda')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'bermuda',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇳',
    description: `${i18n.t('emojis.list.flag-brunei')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'brunei',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇴',
    description: `${i18n.t('emojis.list.flag-bolivia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'bolivia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇶',
    description: `${i18n.t('emojis.list.flag-caribbean-netherlands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'caribbean_netherlands',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇧🇷',
    description: `${i18n.t('emojis.list.flag-brazil')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'brazil',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇸',
    description: `${i18n.t('emojis.list.flag-bahamas')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'bahamas',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇹',
    description: `${i18n.t('emojis.list.flag-bhutan')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'bhutan',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇻',
    description: `${i18n.t('emojis.list.flag-bouvet-island')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'bouvet_island',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🇧🇼',
    description: `${i18n.t('emojis.list.flag-botswana')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'botswana',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇾',
    description: `${i18n.t('emojis.list.flag-belarus')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'belarus',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇧🇿',
    description: `${i18n.t('emojis.list.flag-belize')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'belize',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇨🇦',
    description: `${i18n.t('emojis.list.flag-canada')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'canada',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇨🇨',
    description: `${i18n.t('emojis.list.flag-cocos-keeling-islands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'cocos_islands',
    ],
    tags: [
      'keeling',
    ],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇨🇩',
    description: `${i18n.t('emojis.list.flag-congo-kinshasa')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'congo_kinshasa',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇨🇫',
    description: `${i18n.t('emojis.list.flag-central-african-republic')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'central_african_republic',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇨🇬',
    description: `${i18n.t('emojis.list.flag-congo-brazzaville')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'congo_brazzaville',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇨🇭',
    description: `${i18n.t('emojis.list.flag-switzerland')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'switzerland',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇨🇮',
    description: `${i18n.t('emojis.list.flag-cote-d-ivoire')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'cote_divoire',
    ],
    tags: [
      'ivory',
    ],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇨🇰',
    description: `${i18n.t('emojis.list.flag-cook-islands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'cook_islands',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇨🇱',
    description: `${i18n.t('emojis.list.flag-chile')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'chile',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇨🇲',
    description: `${i18n.t('emojis.list.flag-cameroon')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'cameroon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇨🇳',
    description: `${i18n.t('emojis.list.flag-china')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'cn',
    ],
    tags: [
      'china',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🇨🇴',
    description: `${i18n.t('emojis.list.flag-colombia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'colombia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇨🇵',
    description: `${i18n.t('emojis.list.flag-clipperton-island')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'clipperton_island',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🇨🇷',
    description: `${i18n.t('emojis.list.flag-costa-rica')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'costa_rica',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇨🇺',
    description: `${i18n.t('emojis.list.flag-cuba')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'cuba',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇨🇻',
    description: `${i18n.t('emojis.list.flag-cape-verde')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'cape_verde',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇨🇼',
    description: `${i18n.t('emojis.list.flag-curacao')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'curacao',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇨🇽',
    description: `${i18n.t('emojis.list.flag-christmas-island')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'christmas_island',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇨🇾',
    description: `${i18n.t('emojis.list.flag-cyprus')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'cyprus',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇨🇿',
    description: `${i18n.t('emojis.list.flag-czechia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'czech_republic',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇩🇪',
    description: `${i18n.t('emojis.list.flag-germany')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'de',
    ],
    tags: [
      'flag',
      'germany',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🇩🇬',
    description: `${i18n.t('emojis.list.flag-diego-garcia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'diego_garcia',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🇩🇯',
    description: `${i18n.t('emojis.list.flag-djibouti')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'djibouti',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇩🇰',
    description: `${i18n.t('emojis.list.flag-denmark')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'denmark',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇩🇲',
    description: `${i18n.t('emojis.list.flag-dominica')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'dominica',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇩🇴',
    description: `${i18n.t('emojis.list.flag-dominican-republic')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'dominican_republic',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇩🇿',
    description: `${i18n.t('emojis.list.flag-algeria')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'algeria',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇪🇦',
    description: `${i18n.t('emojis.list.flag-ceuta-melilla')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'ceuta_melilla',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🇪🇨',
    description: `${i18n.t('emojis.list.flag-ecuador')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'ecuador',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇪🇪',
    description: `${i18n.t('emojis.list.flag-estonia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'estonia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇪🇬',
    description: `${i18n.t('emojis.list.flag-egypt')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'egypt',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇪🇭',
    description: `${i18n.t('emojis.list.flag-western-sahara')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'western_sahara',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇪🇷',
    description: `${i18n.t('emojis.list.flag-eritrea')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'eritrea',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇪🇸',
    description: `${i18n.t('emojis.list.flag-spain')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'es',
    ],
    tags: [
      'spain',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🇪🇹',
    description: `${i18n.t('emojis.list.flag-ethiopia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'ethiopia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇪🇺',
    description: `${i18n.t('emojis.list.flag-european-union')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'eu',
      'european_union',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇫🇮',
    description: `${i18n.t('emojis.list.flag-finland')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'finland',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇫🇯',
    description: `${i18n.t('emojis.list.flag-fiji')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'fiji',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇫🇰',
    description: `${i18n.t('emojis.list.flag-falkland-islands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'falkland_islands',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇫🇲',
    description: `${i18n.t('emojis.list.flag-micronesia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'micronesia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇫🇴',
    description: `${i18n.t('emojis.list.flag-faroe-islands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'faroe_islands',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇫🇷',
    description: `${i18n.t('emojis.list.flag-france')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'fr',
    ],
    tags: [
      'france',
      'french',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🇬🇦',
    description: `${i18n.t('emojis.list.flag-gabon')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'gabon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇬🇧',
    description: `${i18n.t('emojis.list.flag-united-kingdom')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'gb',
      'uk',
    ],
    tags: [
      'flag',
      'british',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🇬🇩',
    description: `${i18n.t('emojis.list.flag-grenada')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'grenada',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇬🇪',
    description: `${i18n.t('emojis.list.flag-georgia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'georgia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇬🇫',
    description: `${i18n.t('emojis.list.flag-french-guiana')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'french_guiana',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇬🇬',
    description: `${i18n.t('emojis.list.flag-guernsey')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'guernsey',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇬🇭',
    description: `${i18n.t('emojis.list.flag-ghana')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'ghana',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇬🇮',
    description: `${i18n.t('emojis.list.flag-gibraltar')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'gibraltar',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇬🇱',
    description: `${i18n.t('emojis.list.flag-greenland')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'greenland',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇬🇲',
    description: `${i18n.t('emojis.list.flag-gambia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'gambia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇬🇳',
    description: `${i18n.t('emojis.list.flag-guinea')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'guinea',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇬🇵',
    description: `${i18n.t('emojis.list.flag-guadeloupe')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'guadeloupe',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇬🇶',
    description: `${i18n.t('emojis.list.flag-equatorial-guinea')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'equatorial_guinea',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇬🇷',
    description: `${i18n.t('emojis.list.flag-greece')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'greece',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇬🇸',
    description: `${i18n.t('emojis.list.flag-south-georgia-south-sandwich-islands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'south_georgia_south_sandwich_islands',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇬🇹',
    description: `${i18n.t('emojis.list.flag-guatemala')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'guatemala',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇬🇺',
    description: `${i18n.t('emojis.list.flag-guam')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'guam',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇬🇼',
    description: `${i18n.t('emojis.list.flag-guinea-bissau')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'guinea_bissau',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇬🇾',
    description: `${i18n.t('emojis.list.flag-guyana')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'guyana',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇭🇰',
    description: `${i18n.t('emojis.list.flag-hong-kong-sar-china')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'hong_kong',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇭🇲',
    description: `${i18n.t('emojis.list.flag-heard-mc-donald-islands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'heard_mcdonald_islands',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🇭🇳',
    description: `${i18n.t('emojis.list.flag-honduras')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'honduras',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇭🇷',
    description: `${i18n.t('emojis.list.flag-croatia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'croatia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇭🇹',
    description: `${i18n.t('emojis.list.flag-haiti')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'haiti',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇭🇺',
    description: `${i18n.t('emojis.list.flag-hungary')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'hungary',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇮🇨',
    description: `${i18n.t('emojis.list.flag-canary-islands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'canary_islands',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇮🇩',
    description: `${i18n.t('emojis.list.flag-indonesia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'indonesia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇮🇪',
    description: `${i18n.t('emojis.list.flag-ireland')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'ireland',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇮🇱',
    description: `${i18n.t('emojis.list.flag-israel')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'israel',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇮🇲',
    description: `${i18n.t('emojis.list.flag-isle-of-man')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'isle_of_man',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇮🇳',
    description: `${i18n.t('emojis.list.flag-india')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'india',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇮🇴',
    description: `${i18n.t('emojis.list.flag-british-indian-ocean-territory')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'british_indian_ocean_territory',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇮🇶',
    description: `${i18n.t('emojis.list.flag-iraq')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'iraq',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇮🇷',
    description: `${i18n.t('emojis.list.flag-iran')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'iran',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇮🇸',
    description: `${i18n.t('emojis.list.flag-iceland')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'iceland',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇮🇹',
    description: `${i18n.t('emojis.list.flag-italy')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'it',
    ],
    tags: [
      'italy',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🇯🇪',
    description: `${i18n.t('emojis.list.flag-jersey')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'jersey',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇯🇲',
    description: `${i18n.t('emojis.list.flag-jamaica')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'jamaica',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇯🇴',
    description: `${i18n.t('emojis.list.flag-jordan')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'jordan',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇯🇵',
    description: `${i18n.t('emojis.list.flag-japan')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'jp',
    ],
    tags: [
      'japan',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🇰🇪',
    description: `${i18n.t('emojis.list.flag-kenya')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'kenya',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇰🇬',
    description: `${i18n.t('emojis.list.flag-kyrgyzstan')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'kyrgyzstan',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇰🇭',
    description: `${i18n.t('emojis.list.flag-cambodia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'cambodia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇰🇮',
    description: `${i18n.t('emojis.list.flag-kiribati')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'kiribati',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇰🇲',
    description: `${i18n.t('emojis.list.flag-comoros')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'comoros',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇰🇳',
    description: `${i18n.t('emojis.list.flag-st-kitts-nevis')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'st_kitts_nevis',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇰🇵',
    description: `${i18n.t('emojis.list.flag-north-korea')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'north_korea',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇰🇷',
    description: `${i18n.t('emojis.list.flag-south-korea')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'kr',
    ],
    tags: [
      'korea',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🇰🇼',
    description: `${i18n.t('emojis.list.flag-kuwait')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'kuwait',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇰🇾',
    description: `${i18n.t('emojis.list.flag-cayman-islands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'cayman_islands',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇰🇿',
    description: `${i18n.t('emojis.list.flag-kazakhstan')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'kazakhstan',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇱🇦',
    description: `${i18n.t('emojis.list.flag-laos')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'laos',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇱🇧',
    description: `${i18n.t('emojis.list.flag-lebanon')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'lebanon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇱🇨',
    description: `${i18n.t('emojis.list.flag-st-lucia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'st_lucia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇱🇮',
    description: `${i18n.t('emojis.list.flag-liechtenstein')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'liechtenstein',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇱🇰',
    description: `${i18n.t('emojis.list.flag-sri-lanka')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'sri_lanka',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇱🇷',
    description: `${i18n.t('emojis.list.flag-liberia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'liberia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇱🇸',
    description: `${i18n.t('emojis.list.flag-lesotho')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'lesotho',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇱🇹',
    description: `${i18n.t('emojis.list.flag-lithuania')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'lithuania',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇱🇺',
    description: `${i18n.t('emojis.list.flag-luxembourg')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'luxembourg',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇱🇻',
    description: `${i18n.t('emojis.list.flag-latvia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'latvia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇱🇾',
    description: `${i18n.t('emojis.list.flag-libya')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'libya',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇦',
    description: `${i18n.t('emojis.list.flag-morocco')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'morocco',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇨',
    description: `${i18n.t('emojis.list.flag-monaco')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'monaco',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇲🇩',
    description: `${i18n.t('emojis.list.flag-moldova')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'moldova',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇪',
    description: `${i18n.t('emojis.list.flag-montenegro')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'montenegro',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇫',
    description: `${i18n.t('emojis.list.flag-st-martin')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'st_martin',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🇲🇬',
    description: `${i18n.t('emojis.list.flag-madagascar')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'madagascar',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇭',
    description: `${i18n.t('emojis.list.flag-marshall-islands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'marshall_islands',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇲🇰',
    description: `${i18n.t('emojis.list.flag-north-macedonia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'macedonia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇱',
    description: `${i18n.t('emojis.list.flag-mali')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'mali',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇲',
    description: `${i18n.t('emojis.list.flag-myanmar-burma')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'myanmar',
    ],
    tags: [
      'burma',
    ],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇳',
    description: `${i18n.t('emojis.list.flag-mongolia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'mongolia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇴',
    description: `${i18n.t('emojis.list.flag-macao-sar-china')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'macau',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇵',
    description: `${i18n.t('emojis.list.flag-northern-mariana-islands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'northern_mariana_islands',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇶',
    description: `${i18n.t('emojis.list.flag-martinique')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'martinique',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇲🇷',
    description: `${i18n.t('emojis.list.flag-mauritania')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'mauritania',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇸',
    description: `${i18n.t('emojis.list.flag-montserrat')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'montserrat',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇹',
    description: `${i18n.t('emojis.list.flag-malta')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'malta',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇺',
    description: `${i18n.t('emojis.list.flag-mauritius')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'mauritius',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇲🇻',
    description: `${i18n.t('emojis.list.flag-maldives')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'maldives',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇼',
    description: `${i18n.t('emojis.list.flag-malawi')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'malawi',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇽',
    description: `${i18n.t('emojis.list.flag-mexico')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'mexico',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇾',
    description: `${i18n.t('emojis.list.flag-malaysia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'malaysia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇲🇿',
    description: `${i18n.t('emojis.list.flag-mozambique')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'mozambique',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇳🇦',
    description: `${i18n.t('emojis.list.flag-namibia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'namibia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇳🇨',
    description: `${i18n.t('emojis.list.flag-new-caledonia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'new_caledonia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇳🇪',
    description: `${i18n.t('emojis.list.flag-niger')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'niger',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇳🇫',
    description: `${i18n.t('emojis.list.flag-norfolk-island')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'norfolk_island',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇳🇬',
    description: `${i18n.t('emojis.list.flag-nigeria')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'nigeria',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇳🇮',
    description: `${i18n.t('emojis.list.flag-nicaragua')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'nicaragua',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇳🇱',
    description: `${i18n.t('emojis.list.flag-netherlands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'netherlands',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇳🇴',
    description: `${i18n.t('emojis.list.flag-norway')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'norway',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇳🇵',
    description: `${i18n.t('emojis.list.flag-nepal')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'nepal',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇳🇷',
    description: `${i18n.t('emojis.list.flag-nauru')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'nauru',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇳🇺',
    description: `${i18n.t('emojis.list.flag-niue')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'niue',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇳🇿',
    description: `${i18n.t('emojis.list.flag-new-zealand')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'new_zealand',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇴🇲',
    description: `${i18n.t('emojis.list.flag-oman')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'oman',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇵🇦',
    description: `${i18n.t('emojis.list.flag-panama')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'panama',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇵🇪',
    description: `${i18n.t('emojis.list.flag-peru')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'peru',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇵🇫',
    description: `${i18n.t('emojis.list.flag-french-polynesia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'french_polynesia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇵🇬',
    description: `${i18n.t('emojis.list.flag-papua-new-guinea')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'papua_new_guinea',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇵🇭',
    description: `${i18n.t('emojis.list.flag-philippines')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'philippines',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇵🇰',
    description: `${i18n.t('emojis.list.flag-pakistan')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'pakistan',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇵🇱',
    description: `${i18n.t('emojis.list.flag-poland')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'poland',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇵🇲',
    description: `${i18n.t('emojis.list.flag-st-pierre-miquelon')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'st_pierre_miquelon',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇵🇳',
    description: `${i18n.t('emojis.list.flag-pitcairn-islands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'pitcairn_islands',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇵🇷',
    description: `${i18n.t('emojis.list.flag-puerto-rico')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'puerto_rico',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇵🇸',
    description: `${i18n.t('emojis.list.flag-palestinian-territories')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'palestinian_territories',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇵🇹',
    description: `${i18n.t('emojis.list.flag-portugal')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'portugal',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇵🇼',
    description: `${i18n.t('emojis.list.flag-palau')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'palau',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇵🇾',
    description: `${i18n.t('emojis.list.flag-paraguay')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'paraguay',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇶🇦',
    description: `${i18n.t('emojis.list.flag-qatar')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'qatar',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇷🇪',
    description: `${i18n.t('emojis.list.flag-reunion')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'reunion',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇷🇴',
    description: `${i18n.t('emojis.list.flag-romania')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'romania',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇷🇸',
    description: `${i18n.t('emojis.list.flag-serbia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'serbia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇷🇺',
    description: `${i18n.t('emojis.list.flag-russia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'ru',
    ],
    tags: [
      'russia',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🇷🇼',
    description: `${i18n.t('emojis.list.flag-rwanda')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'rwanda',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇦',
    description: `${i18n.t('emojis.list.flag-saudi-arabia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'saudi_arabia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇧',
    description: `${i18n.t('emojis.list.flag-solomon-islands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'solomon_islands',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇨',
    description: `${i18n.t('emojis.list.flag-seychelles')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'seychelles',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇩',
    description: `${i18n.t('emojis.list.flag-sudan')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'sudan',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇪',
    description: `${i18n.t('emojis.list.flag-sweden')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'sweden',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇬',
    description: `${i18n.t('emojis.list.flag-singapore')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'singapore',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇭',
    description: `${i18n.t('emojis.list.flag-st-helena')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'st_helena',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇸🇮',
    description: `${i18n.t('emojis.list.flag-slovenia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'slovenia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇯',
    description: `${i18n.t('emojis.list.flag-svalbard-jan-mayen')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'svalbard_jan_mayen',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🇸🇰',
    description: `${i18n.t('emojis.list.flag-slovakia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'slovakia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇱',
    description: `${i18n.t('emojis.list.flag-sierra-leone')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'sierra_leone',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇲',
    description: `${i18n.t('emojis.list.flag-san-marino')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'san_marino',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇳',
    description: `${i18n.t('emojis.list.flag-senegal')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'senegal',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇴',
    description: `${i18n.t('emojis.list.flag-somalia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'somalia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇷',
    description: `${i18n.t('emojis.list.flag-suriname')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'suriname',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇸',
    description: `${i18n.t('emojis.list.flag-south-sudan')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'south_sudan',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇹',
    description: `${i18n.t('emojis.list.flag-sao-tome-principe')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'sao_tome_principe',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇻',
    description: `${i18n.t('emojis.list.flag-el-salvador')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'el_salvador',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇽',
    description: `${i18n.t('emojis.list.flag-sint-maarten')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'sint_maarten',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇾',
    description: `${i18n.t('emojis.list.flag-syria')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'syria',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇸🇿',
    description: `${i18n.t('emojis.list.flag-eswatini')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'swaziland',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇹🇦',
    description: `${i18n.t('emojis.list.flag-tristan-da-cunha')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'tristan_da_cunha',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🇹🇨',
    description: `${i18n.t('emojis.list.flag-turks-caicos-islands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'turks_caicos_islands',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇹🇩',
    description: `${i18n.t('emojis.list.flag-chad')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'chad',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇹🇫',
    description: `${i18n.t('emojis.list.flag-french-southern-territories')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'french_southern_territories',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇹🇬',
    description: `${i18n.t('emojis.list.flag-togo')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'togo',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇹🇭',
    description: `${i18n.t('emojis.list.flag-thailand')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'thailand',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇹🇯',
    description: `${i18n.t('emojis.list.flag-tajikistan')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'tajikistan',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇹🇰',
    description: `${i18n.t('emojis.list.flag-tokelau')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'tokelau',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇹🇱',
    description: `${i18n.t('emojis.list.flag-timor-leste')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'timor_leste',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇹🇲',
    description: `${i18n.t('emojis.list.flag-turkmenistan')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'turkmenistan',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇹🇳',
    description: `${i18n.t('emojis.list.flag-tunisia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'tunisia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇹🇴',
    description: `${i18n.t('emojis.list.flag-tonga')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'tonga',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇹🇷',
    description: `${i18n.t('emojis.list.flag-turkey')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'tr',
    ],
    tags: [
      'turkey',
    ],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    emoji: '🇹🇹',
    description: `${i18n.t('emojis.list.flag-trinidad-tobago')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'trinidad_tobago',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇹🇻',
    description: `${i18n.t('emojis.list.flag-tuvalu')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'tuvalu',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇹🇼',
    description: `${i18n.t('emojis.list.flag-taiwan')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'taiwan',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇹🇿',
    description: `${i18n.t('emojis.list.flag-tanzania')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'tanzania',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇺🇦',
    description: `${i18n.t('emojis.list.flag-ukraine')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'ukraine',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇺🇬',
    description: `${i18n.t('emojis.list.flag-uganda')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'uganda',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇺🇲',
    description: `${i18n.t('emojis.list.flag-u-s-outlying-islands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'us_outlying_islands',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🇺🇳',
    description: `${i18n.t('emojis.list.flag-united-nations')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'united_nations',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🇺🇸',
    description: `${i18n.t('emojis.list.flag-united-states')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'us',
    ],
    tags: [
      'flag',
      'united',
      'america',
    ],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    emoji: '🇺🇾',
    description: `${i18n.t('emojis.list.flag-uruguay')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'uruguay',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇺🇿',
    description: `${i18n.t('emojis.list.flag-uzbekistan')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'uzbekistan',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇻🇦',
    description: `${i18n.t('emojis.list.flag-vatican-city')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'vatican_city',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇻🇨',
    description: `${i18n.t('emojis.list.flag-st-vincent-grenadines')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'st_vincent_grenadines',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇻🇪',
    description: `${i18n.t('emojis.list.flag-venezuela')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'venezuela',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇻🇬',
    description: `${i18n.t('emojis.list.flag-british-virgin-islands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'british_virgin_islands',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇻🇮',
    description: `${i18n.t('emojis.list.flag-u-s-virgin-islands')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'us_virgin_islands',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇻🇳',
    description: `${i18n.t('emojis.list.flag-vietnam')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'vietnam',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇻🇺',
    description: `${i18n.t('emojis.list.flag-vanuatu')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'vanuatu',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇼🇫',
    description: `${i18n.t('emojis.list.flag-wallis-futuna')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'wallis_futuna',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇼🇸',
    description: `${i18n.t('emojis.list.flag-samoa')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'samoa',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇽🇰',
    description: `${i18n.t('emojis.list.flag-kosovo')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'kosovo',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇾🇪',
    description: `${i18n.t('emojis.list.flag-yemen')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'yemen',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇾🇹',
    description: `${i18n.t('emojis.list.flag-mayotte')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'mayotte',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    emoji: '🇿🇦',
    description: `${i18n.t('emojis.list.flag-south-africa')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'south_africa',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇿🇲',
    description: `${i18n.t('emojis.list.flag-zambia')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'zambia',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🇿🇼',
    description: `${i18n.t('emojis.list.flag-zimbabwe')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'zimbabwe',
    ],
    tags: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    description: `${i18n.t('emojis.list.flag-england')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'england',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
    description: `${i18n.t('emojis.list.flag-scotland')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'scotland',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    emoji: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
    description: `${i18n.t('emojis.list.flag-wales')}`,
    category: `${i18n.t('emojis.categories.flags')}`,
    aliases: [
      'wales',
    ],
    tags: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
]
